import {
	useTheme,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	Select,
	Stack,
	TextField,
	Tooltip,
	InputLabel,
	FormControl,
	Typography,
} from '@mui/material';
import TableRowsIcon from '@mui/icons-material/TableRows';
import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import Header from '../../components/Header/Header';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import useHttpService from '../../customHooks/useHttpService.js';
import ElementManagers from '../elementManagers/ElementManagers';
import { ToastContainer, toast } from 'react-toastify';
import { Delete, Edit, ContentCopy, Refresh } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { tokens } from '../../theme';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const ManagementNodes = () => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const location = useLocation();
	const tableInstanceRef = useRef(null);
	const navigate = useNavigate();
	const toVendorsPage = (vendorID) => {
		navigate('/vendors', { state: { vendorID: vendorID } });
	};
	const toNetworkFunctionPage = (mnName) => {
		navigate('/nfs', { state: { mnName: mnName } });
	};

	// main data table
	const [tableData, setTableData] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);
	const [isErrorUpdate, setIsErrorUpdate] = useState(false);
	const [isErrorCreate, setIsErrorCreate] = useState(false);
	const [isErrorDelete, setIsErrorDelete] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState('');
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [validationErrors, setValidationErrors] = useState({});

	const [confirmDiscoveryOpen, setConfirmDiscoveryOpen] = useState(false);
	const [discoveryRow, setDiscoveryRow] = useState(null);

	const fetchTableData = () => {
		const apiPath = '/nf_fcaps/managed_object/ManagementNode_EM';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		//apiUrl.searchParams.set("size", `${pagination.pageSize}`);
		//apiUrl.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
		//apiUrl.searchParams.set("globalFilter", globalFilter ?? "");
		//apiUrl.searchParams.set("sorting", JSON.stringify(sorting ?? []));

		httpService.getAPI(
			apiUrl,
			tableData,
			setTableData,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	const handleCreateNewRow = (values) => {
		const apiPath = '/nf_fcaps/managed_object/ManagementNode';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		delete values['id'];
		httpService.postAPI(apiUrl, values, setIsErrorCreate, setFetchRequired);
	};

	const handleSaveRowEdits = ({ exitEditingMode, row, values }) => {
		const apiPath = '/nf_fcaps/managed_object/ManagementNode';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		if (!Object.keys(validationErrors).length) {
			httpService.putAPI(apiUrl, values, setIsErrorUpdate, setFetchRequired);
			exitEditingMode();
		}
	};

	const handleCancelRowEdits = () => {
		setValidationErrors({});
	};

	const handleDeleteRow = (row) => {
		if (
			!window.confirm(
				`Are you sure you want to delete ${row.getValue('userLabel')}`
			)
		) {
			return;
		}
		const apiPath = '/nf_fcaps/managed_object/ManagementNode_EM';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.deleteAPI(
			apiUrl,
			{ ids: [row.id] },
			setIsErrorDelete,
			setFetchRequired
		);
	};

	const getAlert = () => {
		if (isError) {
			return {
				color: 'error',
				children: 'Error loading data',
			};
		} else if (isErrorCreate) {
			return {
				color: 'error',
				children: 'Error creating new item',
			};
		} else if (isErrorUpdate) {
			return {
				color: 'error',
				children: 'Error updating the item',
			};
		} else if (isErrorDelete) {
			return {
				color: 'error',
				children: 'Error deleting the item',
			};
		}

		return undefined;
	};

	const validateRequired = (value) => !!value.length;

	const getCommonEditTextFieldProps = useCallback(
		(cell) => {
			return {
				error: !!validationErrors[cell.id],
				helperText: validationErrors[cell.id],
				onBlur: (event) => {
					const isValid = validateRequired(event.target.value);
					if (!isValid) {
						//set validation error for cell if invalid
						setValidationErrors({
							...validationErrors,
							[cell.id]: `${cell.column.columnDef.header} is required`,
						});
					} else {
						//remove validation error for cell if valid
						delete validationErrors[cell.id];
						setValidationErrors({
							...validationErrors,
						});
					}
				},
			};
		},
		[validationErrors]
	);

	const minifyID = (ID) => {
		return ID.split('-')[0] + ' ' + '\u00B7'.repeat(3);
	};

	useEffect(() => {
		if (fetchRequired) {
			fetchTableData();
			setFetchRequired(false);
		}
	}, [fetchRequired]);

	useEffect(() => {
		if (isErrorDelete) {
			const timeId = setTimeout(() => {
				setIsErrorDelete(false);
			}, 5000);

			return () => {
				clearTimeout(timeId);
			};
		}
	}, [isErrorDelete]);

	useEffect(() => {
		if (isErrorUpdate) {
			const timeId = setTimeout(() => {
				setIsErrorUpdate(false);
			}, 5000);

			return () => {
				clearTimeout(timeId);
			};
		}
	}, [isErrorUpdate]);

	useEffect(() => {
		if (isErrorCreate) {
			const timeId = setTimeout(() => {
				setIsErrorCreate(false);
			}, 5000);

			return () => {
				clearTimeout(timeId);
			};
		}
	}, [isErrorCreate]);

	useEffect(() => {
		if (location.state && location.state.mnId) {
			tableInstanceRef.current.setColumnFilters((prev) => [
				...prev,
				{ id: 'id', value: location.state.mnId },
			]);
		}
	}, [location]);

	// combobox of plugins
	const [pluginList, setPluginList] = useState([]);
	const [pluginCombo, setPluginCombo] = useState([]);
	const [pluginFetchRequired, setPluginFetchRequired] = useState(true);
	const [pluginIsError, setPluginIsError] = useState(false);
	const [pluginIsLoading, setPluginIsLoading] = useState(false);
	const [pluginIsRefetching, setPluginIsRefetching] = useState(false);
	const [pluginRowCount, setPluginRowCount] = useState(0);

	const fetchPluginList = () => {
		const apiPath = '/nf_fcaps/managed_object/Plugin';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.getAPI(
			apiUrl,
			pluginList,
			setPluginList,
			setPluginRowCount,
			setPluginIsLoading,
			setPluginIsRefetching,
			setPluginIsError
		);
	};

	const mapCombo = (jsonData, value) => {
		const item = jsonData.find((data) => data.value === value);
		return item ? item.text : value;
	};

	useEffect(() => {
		if (pluginFetchRequired) {
			fetchPluginList();
			setPluginFetchRequired(false);
		}
	}, [pluginFetchRequired]);

	useEffect(() => {
		if (pluginList) {
			setPluginCombo(
				pluginList.map(({ id, name }) => ({ value: id, text: name }))
			);
		}
	}, [pluginList]);

	// modal of Element Managers table
	const [openEM, setOpenEM] = useState(false);
	const [EMID, setEMID] = useState([]);
	const handleClickOpenEM = (EMID) => {
		setEMID(EMID);
		setOpenEM(true);
	};
	const handleCloseEM = () => {
		setOpenEM(false);
		setFetchRequired(true);
	};

	const onDiscoverySuccess = () => {
		toast.success('Discovery action passed successfully');
	};

	const onDiscoveryError = () => {
		toast.error('Error occurred while passing the discovery action');
	};

	const handleDiscovery = () => {
		if (!discoveryRow) return;

		const apiPath = '/celery/push/OPERMOI/discovery';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_QUEUE_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_QUEUE_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

		const plugin = pluginCombo.find(
			(plugin) => plugin.value === discoveryRow.original.Plugin_id
		);

		if (!plugin) return;

		const data = {
			Name: 'discovery',
			moi_orig: plugin.text,
			moi_class: 'NSSI',
			moi_id: discoveryRow.original.id,
			ne_id: discoveryRow.original.id,
			Command: 'discovery',
		};

		httpService.postQueue(apiUrl, data, onDiscoverySuccess, onDiscoveryError);
	};

	const columns = useMemo(
		() => [
			{
				accessorKey: 'id',
				header: 'ID',
				enableColumnOrdering: false,
				enableEditing: false,
				enableSorting: false,
				enableClickToCopy: true,
				size: 60,
				isInput: false,
				Cell: ({ row }) => (
					<Tooltip title={row.original.id} arrow>
						<Typography variant='h6' color='primary'>
							{minifyID(row.original.id)}
						</Typography>
					</Tooltip>
				),
				muiTableBodyCellEditTextFieldProps: {
					type: 'string',
					variant: 'outlined',
				},
			},
			{
				accessorKey: 'userLabel',
				header: 'Name',
				size: 60,
				isInput: true,
				muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
					...getCommonEditTextFieldProps(cell),
					required: true,
					variant: 'outlined',
				}),
			},
			{
				accessorKey: 'userDefinedState',
				header: 'State',
				size: 60,
				isInput: true,
				editVariant: 'select',
				editSelectOptions: [
					{ value: 'LOCKED', text: 'LOCKED' },
					{ value: 'UNLOCKED', text: 'UNLOCKED' },
				],
				muiTableBodyCellEditTextFieldProps: {
					required: true,
					type: 'select',
					variant: 'outlined',
				},
			},
			{
				accessorKey: 'Plugin_id',
				header: 'Plugin',
				size: 60,
				enableEditing: false,
				isInput: true,
				editVariant: 'select',
				editSelectOptions: pluginCombo,
				isVisible: true,
				Cell: ({ cell }) => mapCombo(pluginCombo, cell.getValue()),
				muiTableBodyCellEditTextFieldProps: {
					required: true,
					type: 'string',
					variant: 'outlined',
				},
			},
			{
				accessorKey: 'Vendor_name',
				header: 'Vendor',
				size: 60,
				enableEditing: false,
				isInput: false,
				Edit: ({}) => undefined,
				Cell: ({ row }) => (
					<Tooltip title='Click to open' arrow placement='top-start'>
						<Typography
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								toVendorsPage(row.original.Vendor_id);
							}}
							variant='h6'
							color='primary'>
							{row.original.Vendor_name}
						</Typography>
					</Tooltip>
				),
			},
			{
				header: 'Element Managers',
				columnDefType: 'display',
				isInput: false,
				size: 180,
				Edit: ({}) => undefined,
				Cell: ({ row }) => (
					<Box sx={{ display: 'flex', gap: '1rem' }}>
						<Tooltip arrow placement='top-start' title='Element Managers'>
							<IconButton onClick={() => handleClickOpenEM(row.original.id)}>
								<MenuOpenIcon />
							</IconButton>
						</Tooltip>
						{row.original.ElementManagers && (
							<Box
								sx={{
									display: 'grid',
									margin: 'auto',
									gridTemplateColumns: '40px 40px',
									width: '100%',
								}}>
								{row.original.ElementManagers.map((item) => (
									<Typography>{item.EMType}</Typography>
								))}
							</Box>
						)}
						{!row.original.ElementManagers && (
							<Box sx={{ display: 'flex', gap: '1rem' }}>
								<Typography>No EM found</Typography>
							</Box>
						)}
					</Box>
				),
			},
		],
		[pluginCombo, getCommonEditTextFieldProps]
	);
	return (
        <Box m="20px">
            <Header
                title="Management Nodes - Element Managers"
                subtitle="List of Management Nodes (MN) and corresponding Element Managers (EM)"
            />

            <MaterialReactTable
				tableInstanceRef={tableInstanceRef}
                sx={{
                    "&::-webkit-scrollbar": {
                        width: "0.4em",
                    },
                    "&::-webkit-scrollbar-track": {
                        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#6486c2",
                        outline: "1px solid slategrey",
                        "&:hover": {
                            backgroundColor: "#5A7BB0",
                        },
                        "&:active": {
                            backgroundColor: "#4D6E9E",
                        },
                    },
                }}
                enableStickyHeader
                muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
                displayColumnDefOptions={{
                    "mrt-row-actions": {
                        muiTableHeadCellProps: {
                            align: "left",
                            sx: {
                                fontWeight: "bold",
                                color: colors.grey[100],
                                typography: "h5",
                            },
                        },
                        muiTableBodyCellProps: {
                            align: "left",
                        },
                        size: 60,
                    },
                }}
                positionActionsColumn="last"
                columns={columns}
                data={tableData}
                getRowId={(row) => row.id}
                initialState={{
                    showColumnFilters: true,
                    showGlobalFilter: true,
                    density: "compact",
                }}
                enableDensityToggle={false}
                muiTableHeadCellFilterTextFieldProps={{
                    sx: { m: "0.3rem 0", width: "100%" },
                }}
                muiTableHeadCellProps={{
                    align: "left",
                    sx: {
                        fontWeight: "bold",
                        color: colors.grey[100],
                        typography: "h5",
                    },
                }}
                muiTableBodyCellProps={{
                    align: "left",
                    sx: {
                        color: colors.grey[100],
                        typography: "h6",
                    },
                }}
                muiToolbarAlertBannerProps={getAlert()}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner:
                        isError ||
                        isErrorCreate ||
                        isErrorUpdate ||
                        isErrorDelete,
                    showProgressBars: isRefetching,
                    sorting,
                }}
                editingMode="modal"
                enableColumnOrdering={false}
                enableHiding={false}
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                enableRowActions
                renderBottomToolbarCustomActions={() => {
                    return (
                        <div>
                            <Tooltip arrow title="Refresh">
                                <IconButton
                                    onClick={() => {
                                        setFetchRequired(true);
                                    }}
                                >
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                }}
                renderRowActionMenuItems={({ row, table }) => [
                    <MenuItem
                        key="Network Functions"
                        onClick={() => {
                            toNetworkFunctionPage(row.original.userLabel);
                        }}
                    >
                        <ListItemIcon>
                            <MenuOpenIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: "h6" }}
                            primary="Network Functions"
                        />
                    </MenuItem>,
                    <MenuItem
                        key="discovery"
                        onClick={() => {
                            setConfirmDiscoveryOpen(true);
                            setDiscoveryRow(row);
                        }}
                    >
                        <ListItemIcon>
                            <Refresh fontSize="large" color="success" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: "h6" }}
                            primary="Discovery"
                        />
                    </MenuItem>,
                    <MenuItem
                        key="edit"
                        onClick={() => table.setEditingRow(row)}
                    >
                        <ListItemIcon>
                            <Edit fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: "h6" }}
                            primary="Edit"
                        />
                    </MenuItem>,
                    <MenuItem key="delete" onClick={() => handleDeleteRow(row)}>
                        <ListItemIcon>
                            <Delete fontSize="large" color="error" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: "h6" }}
                            primary="Delete"
                        />
                    </MenuItem>,
                ]}
                renderTopToolbarCustomActions={() => (
                    <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
                        <Button
                            color="secondary"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                            startIcon={
                                <AddCircleOutlineIcon
                                    sx={{ color: "#FFFFFF" }}
                                />
                            }
                        >
                            <Typography variant="h7" sx={{ color: "#FFFFFF" }}>
                                Create New Management Node
                            </Typography>
                        </Button>
                    </Box>
                )}
            />

            <CreateNewItemModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                createItemName={"Management Node"}
            />
            <ConfirmDialog
                title="Confirm Discovery"
                open={confirmDiscoveryOpen}
                setOpen={setConfirmDiscoveryOpen}
                onConfirm={handleDiscovery}
            >
                <Box sx={{ display: "block", gap: "1rem", p: "4px" }}>
                    <Typography variant="h5">
                        You are about to perform a Discovery on the following
                        Management Node
                    </Typography>
                    <Typography variant="h6">
                        ID: {discoveryRow && discoveryRow.original.id}
                    </Typography>
                    <Typography variant="h6">
                        Name: {discoveryRow && discoveryRow.original.userLabel}
                    </Typography>
                </Box>
            </ConfirmDialog>

            <Dialog
                maxWidth="xl"
                open={openEM}
                PaperProps={{
                    sx: {
                        bgcolor: colors.primary[400],
                        width: "100%",
                        maxWidth: "80%",
                    },
                }}
            >
                <DialogContent>
                    <ElementManagers EMID={EMID} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEM} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Box m="20px">
                {/* ... */}
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                />
            </Box>
        </Box>
    );
};

export const CreateNewItemModal = ({
	open,
	columns,
	onClose,
	onSubmit,
	createItemName,
}) => {
	const [values, setValues] = useState(() =>
		columns.reduce((acc, column) => {
			acc[column.accessorKey ?? ''] = '';
			return acc;
		}, {})
	);

	const handleSubmit = () => {
		onSubmit(values);
		onClose();
	};

	useEffect(() => {
		columns.forEach((column) => {
			if (column.fixedValue) {
				values[column.accessorKey] = column.fixedValue;
			}
		});
	}, []);

	return (
		<Dialog open={open}>
			<DialogTitle textAlign='center'>
				{'Create New ' + createItemName}
			</DialogTitle>
			<DialogContent>
				<form onSubmit={(e) => e.preventDefault()}>
					<Stack
						sx={{
							width: '100%',
							minWidth: { xs: '300px', sm: '360px', md: '400px' },
							gap: '1.5rem',
							mt: 2,
						}}>
						{columns.map(
							(column) =>
								column.isInput &&
								((column.fixedValue && (
									<TextField
										required
										key={column.accessorKey}
										label={column.header}
										name={column.accessorKey}
										disabled={true}
										value={column.fixedValue}
										onChange={(e) =>
											setValues({
												...values,
												[e.target.name]: e.target.value,
											})
										}
									/>
								)) ||
									(column.editVariant != 'select' && (
										<TextField
											required
											key={column.accessorKey}
											label={column.header}
											name={column.accessorKey}
											onChange={(e) =>
												setValues({
													...values,
													[e.target.name]: e.target.value,
												})
											}
										/>
									)) ||
									(column.editVariant == 'select' && (
										<FormControl fullWidth required>
											<InputLabel id='select-label'>{column.header}</InputLabel>
											<Select
												id={column.accessorKey}
												name={column.accessorKey}
												labelId='select-label'
												label={column.header}
												onChange={(e) =>
													setValues({
														...values,
														[e.target.name]: e.target.value,
													})
												}
												sx={{
													width: '100%',
												}}>
												<MenuItem value='' disabled>
													Select an option
												</MenuItem>
												{column.editSelectOptions.map((item) => (
													<MenuItem
														//key={item.value}
														value={item.value}
														//name={item.value}
													>
														{item.text}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)))
						)}
					</Stack>
				</form>
			</DialogContent>
			<DialogActions sx={{ p: '1.25rem' }}>
				<Button onClick={onClose}>Cancel</Button>
				<Button color='secondary' onClick={handleSubmit} variant='contained'>
					{'Create'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ManagementNodes;
