import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import Header from '../../components/Header/Header';

import DataTable from '../../components/DataTable/DataTable';

const Snssai = () => {
	const columns = useMemo(
		() => [
			{
				accessorKey: 'id',
				header: 'ID',
				enableColumnOrdering: false,
				enableEditing: false,
				enableSorting: false,
				enableClickToCopy: true,
				size: 200,
				isInput: false,
			},
			{
				accessorKey: 'userLabel',
				header: 'Name',
				size: 100,
				isInput: true,
			},
			{
				accessorKey: 'sst',
				header: 'SST',
				size: 60,
				enableEditing: false,
				isInput: true,
			},
			{
				accessorKey: 'sd',
				header: 'SD',
				size: 60,
				enableEditing: false,
				isInput: true,
			},
		],
		[]
	);

	return (
		<Box m='20px'>
			<Header
				title='SNSSAI'
				subtitle='List of Single Network Slice Selection Assistance Information (SNSSAI)'
			/>
			<DataTable
				apiPath={{
					get: '/nf_fcaps/managed_object/Snssai',
					post: '/nf_fcaps/managed_object/Snssai',
					put: '/nf_fcaps/managed_object/Snssai',
					delete: '/nf_fcaps/managed_object/Snssai',
				}}
				columns={columns}
				createItemName='SNSSAI'
				showCreateButton={true}
				showEditButton={true}
				showDeleteButton={true}
				showDownloadButton={false}
				showDownloadAllButton={false}
				showDiscoveryButton={false}
			/>
		</Box>
	);
};

export default Snssai;
