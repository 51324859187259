import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
    Grid,
    Stack,
    FormControl,
    InputLabel,
    
} from "@mui/material";

const EditDialog = (props) => {
    const { rowData, schema, sections, open, setOpen, onSave } = props;
    const [editedData, setEditedData] = useState(rowData);

    useEffect(() => {
        setEditedData(rowData);
    }, [rowData]);

    const handleChange = (e, accessorKey) => {
        const { value } = e.target;
        setEditedData((prevData) => ({
            ...prevData,
            [accessorKey]: value,
        }));
    };

    const renderField = (field, rowData) => {
        const value = rowData[field.accessorKey];

        switch (field.type) {
            case "text":
                return (
                    <TextField
                        label={field.label}
                        value={value || ""}
                        disabled={field.disabled}
                        onChange={(e) => handleChange(e, field.accessorKey)}
                        sx={{
                            width: field.width || "50%",
                        }}
                    />
                );
            case "number":
                return (
                    <TextField
                        label={field.label}
                        type="number"
                        value={value}
                        disabled={field.disabled}
                        onChange={(e) => handleChange(e, field.accessorKey)}
                        sx={{
                            width: field.width || "50%",
                        }}
                    />
                );
            case "select":
                return (
                    <FormControl fullWidth required>
                        <InputLabel id="select-label">{field.label}</InputLabel>
                        <Select
                            value={value}
                            label={field.label}
                            onChange={(e) => handleChange(e, field.accessorKey)}
                            labelId="select-label"
                            sx={{
                                width: field.width || "50%",
                            }}
                        >
                            <MenuItem value="" disabled>
                                Select an option
                            </MenuItem>
                            {field.options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            // Add more field types as needed

            default:
                return null;
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="xl"
            PaperProps={{
                sx: {
                    width: "100%",
                    height: "80%",
                    maxWidth: "60%",
                    minHeight: "50%",
                },
            }}
        >
            <DialogTitle>Edit Row</DialogTitle>
            <DialogContent>
            <form onSubmit={(e) => e.preventDefault()}>
                    <Grid container spacing={2}>
                        {sections.map((section) => (
                            <Grid item xs={12} key={section.sectionKey}>
                                <h3>{section.sectionLabel}</h3>
                                <Stack
                                    spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap"
                                >
                                    {schema.map((field) => {
                                        if (
                                            field.section === section.sectionKey
                                        ) {
                                            return (
                                                <div key={field.accessorKey}>
                                                    {renderField(
                                                        field,
                                                        editedData
                                                    )}
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setOpen(false);
                        onSave(editedData);
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditDialog;
