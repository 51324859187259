import { useState } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import WebAssetOutlinedIcon from '@mui/icons-material/WebAssetOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AlarmIcon from '@mui/icons-material/Alarm';
import CellTowerOutlinedIcon from '@mui/icons-material/CellTowerOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { CasesOutlined } from '@mui/icons-material';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';

const Item = ({ title, to, icon }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const location = useLocation();

	return (
		<MenuItem
			active={location.pathname === to}
			style={{
				color: colors.grey[100],
			}}
			onClick={() => {}}
			icon={icon}>
			<Typography>{title}</Typography>
			<Link to={to} />
		</MenuItem>
	);
};

const Sidebar = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [isCollapsed, setIsCollapsed] = useState(false);

	return (
		<Box
			sx={{
				'& .pro-sidebar-inner': {
					background: `${colors.primary[400]} !important`,
				},
				'& .pro-icon-wrapper': {
					backgroundColor: 'transparent !important',
				},
				'& .pro-inner-item': {
					padding: '5px 35px 5px 10px !important',
				},
				'& .pro-inner-item:hover': {
					color: '#868dfb !important',
				},
				'& .pro-menu-item.active': {
					color: '#6870fa !important',
				},
			}}>
			<ProSidebar collapsed={isCollapsed}>
				<Menu iconShape='square'>
					{/* LOGO AND MENU ICON */}
					<MenuItem
						onClick={() => setIsCollapsed(!isCollapsed)}
						icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
						style={{
							margin: '5px 0 10px 0',
							color: colors.grey[100],
						}}>
						{!isCollapsed && (
							<Box display='flex' justifyContent='space-between' ml='15px'>
								<Box mb='25px'>
									<Box textAlign='center'>
										<Typography
											variant='h3'
											color={colors.grey[100]}
											fontWeight='bold'
											sx={{ m: '10px 0 0 0' }}>
											NF-FCAPS
										</Typography>
										<Typography variant='h5' color={colors.skyAccent[500]}>
											Admin Portal
										</Typography>
									</Box>
								</Box>
								<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
									<MenuOutlinedIcon />
								</IconButton>
							</Box>
						)}
					</MenuItem>
					<Box paddingLeft={isCollapsed ? undefined : '10%'}>
						<Item title='Dashboard' to='/' icon={<HomeOutlinedIcon />} />
						{!isCollapsed && (
							<Typography
								variant='h6'
								color={colors.grey[300]}
								sx={{ m: '15px 0 5px 20px' }}>
								Managed Elements
							</Typography>
						)}
						{isCollapsed && (
							<Typography
								variant='h6'
								color={colors.grey[300]}
								sx={{ m: '15px 0 5px 20px' }}>
								-----
							</Typography>
						)}
						<Item
							title='Network Functions'
							to='/nfs'
							icon={<WebAssetOutlinedIcon />}
						/>
						<Item
							title='Network Services'
							to='/ns'
							icon={<WebAssetOutlinedIcon />}
						/>
						{!isCollapsed && (
							<Typography
								variant='h6'
								color={colors.grey[300]}
								sx={{ m: '15px 0 5px 20px' }}>
								Settings
							</Typography>
						)}
						{isCollapsed && (
							<Typography
								variant='h6'
								color={colors.grey[300]}
								sx={{ m: '15px 0 5px 20px' }}>
								-----
							</Typography>
						)}
						<Item
							title='PLMNID'
							to='/plmnid'
							icon={<CellTowerOutlinedIcon />}
						/>
						<Item title='SNSSAI' to='/snssai' icon={<SpeedOutlinedIcon />} />
						<Item
							title='PLMNINFO'
							to='/plmninfo'
							icon={<ArticleOutlinedIcon />}
						/>
						<Item title='Vendors' to='/vendors' icon={<CasesOutlined />} />
						<Item
							title='Management Nodes'
							to='/mnem'
							icon={<RoomPreferencesIcon />}
						/>
						{!isCollapsed && (
							<Typography
								variant='h6'
								color={colors.grey[300]}
								sx={{ m: '15px 0 5px 20px' }}>
								Monitoring
							</Typography>
						)}
						{isCollapsed && (
							<Typography
								variant='h6'
								color={colors.grey[300]}
								sx={{ m: '15px 0 5px 20px' }}>
								-----
							</Typography>
						)}
						<Item
							title='Notifications'
							to='/notifications'
							icon={<NotificationsActiveIcon />}
						/>
						<Item
							title='Alarms'
							to='/alarms'
							icon={<AlarmIcon />}
						/>
					</Box>
				</Menu>
			</ProSidebar>
		</Box>
	);
};

export default Sidebar;
