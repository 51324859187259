import {
	Box,
	IconButton,
	useTheme,
	Typography,
	Popover,
	Button,
	Menu,
	Badge,
	Divider,
} from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import { ColorModeContext, tokens } from '../../theme';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { useUserContext } from '../../contexts/userContext.js';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { convertToDate } from '../../utils';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer } from 'react-toastify';
import { useSignalR } from '../../contexts/signalrContext';

const Topbar = () => {
	const { addMessageCallback, removeMessageCallback } = useSignalR();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const colorMode = useContext(ColorModeContext);
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const userContext = useUserContext();
	const user = userContext.getUser();

	const [notifications, setNotifications] = useState([]);
	const [anchorNotifications, setAnchorNotifications] = useState(null);
	const handleNotificationsOpen = (event) => {
		setAnchorNotifications(event.currentTarget);
	};

	const handleNotificationsClose = () => {
		setAnchorNotifications(null);
	};

	const handleClickNotification = (index, notification) => {
		if (notification.category === 'NOTIFICATION') {
			navigate('/notifications', {
				state: { notificationId: notification.id },
			});
		} else if (notification.category === 'ALARM') {
			navigate('/alarms', { state: { notificationId: notification.id } });
		}
		setNotifications((prevNotifications) => {
			const updatedNotifications = [...prevNotifications];
			updatedNotifications.splice(index, 1); // Remove the clicked notification
			return updatedNotifications;
		});
		handleNotificationsClose();
	};

	const handleRemoveNotification = (index) => {
		setNotifications((prevNotifications) => {
			const updatedNotifications = [...prevNotifications];
			updatedNotifications.splice(index, 1);
			return updatedNotifications;
		});
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const parseNotificationMessage = (message) => {
		const [category, notificationType, seppFunction, inserted, id] =
			message.split('__');
		return {
			category: category,
			notificationType: notificationType,
			seppFunction: seppFunction,
			inserted: convertToDate(inserted),
			id: id,
		};
	};

	const handleClearNotifications = () => {
		setNotifications([]);
	};

	useEffect(() => {
		const handleNewMessage = (message) => {
			// Process the new message here
			// Make decisions based on the message
			if (message.includes('NOTIFICATION__') || message.includes('ALARM__')) {
				const newNotification = parseNotificationMessage(message);
				setNotifications((prevNotifications) => {
					// Add new notification to the beginning of the array
					prevNotifications.unshift(newNotification);
					return [...prevNotifications];
				});
			}
		};

		// Register the message handling function
		addMessageCallback(handleNewMessage);

		// Clean up
		return () => {
			// Unregister the message handling function if necessary
			removeMessageCallback(handleNewMessage);
		};
	}, []);

	return (
		<Box>
			<Box display='flex' justifyContent='space-between' p={2}>
				{' '}
				{/* LOGO */}
				<Box display='flex' justifyContent='space-between'>
					<Typography variant='h3' color={colors.grey[400]}>
						<Box display='flex' justifyContent='center' alignItems='center'>
							<img
								alt='NTTDATA-LOGO'
								width='181px'
								height='50px'
								src={
									theme.palette.mode === 'dark'
										? `../../assets/nttdata-dark.png`
										: `../../assets/nttdata-light.png`
								}
								style={{ cursor: 'pointer' }}
							/>
						</Box>
					</Typography>
				</Box>
				{/* ICONS */}
				<Box display='flex'>
					<IconButton onClick={colorMode.toggleColorMode}>
						{theme.palette.mode === 'dark' ? (
							<DarkModeOutlinedIcon />
						) : (
							<LightModeOutlinedIcon />
						)}
					</IconButton>
					<IconButton color='inherit' onClick={handleNotificationsOpen}>
						<Badge badgeContent={notifications.length} color='secondary'>
							<NotificationsOutlinedIcon />
						</Badge>
					</IconButton>
					<Menu
						anchorEl={anchorNotifications}
						open={Boolean(anchorNotifications)}
						onClose={handleNotificationsClose}
						sx={{
							mt: '1px',
							'& .MuiMenu-paper': { backgroundColor: colors.primary[400] },
						}}
					>
						<Box
							sx={{
								maxHeight: '400px',
								overflowY: 'auto',
								padding: '10px',
								width: '500px',
							}}
						>
							{notifications.map((notification, index) => (
								<>
									<Box
										key={index}
										sx={{ position: 'relative', padding: '10px' }}
									>
										<Box
											sx={{ cursor: 'pointer', width: '90%' }}
											onClick={() =>
												handleClickNotification(index, notification)
											}
										>
											<Typography
												variant='body1'
												style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
											>
												{`Received a ${notification.notificationType} from ${notification.seppFunction} at ${notification.inserted}`}
											</Typography>
										</Box>
										<IconButton
											sx={{ position: 'absolute', top: '5px', right: '5px' }}
											onClick={() => handleRemoveNotification(index)}
										>
											<CloseIcon />
										</IconButton>
									</Box>
									{index !== notifications.length - 1 && <Divider />}
								</>
							))}
							<Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Button variant='outlined' onClick={handleClearNotifications}>
									Clear Notifications
								</Button>
								<Button variant='outlined' onClick={handleNotificationsClose}>
									Close
								</Button>
							</div>
						</Box>
					</Menu>
					<IconButton onClick={handleClick}>
						<PersonOutlinedIcon />
					</IconButton>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<Box sx={{ p: 2 }}>
							<Typography display='inline'>Logged in as: </Typography>
							<Typography display='inline' fontWeight='bold' color='secondary'>
								{user ? user.name : 'Unknown User'}
							</Typography>
						</Box>
						<Box display='flex' justifyContent='center'>
							<Button
								variant='contained'
								color='secondary'
								fullWidth
								sx={{ mt: 2 }}
								onClick={() => {
									userContext.logout();
								}}
								startIcon={<ExitToAppIcon />}
							>
								Sign out
							</Button>
						</Box>
					</Popover>
				</Box>
			</Box>
			<Box m='20px'>
				{/* ... */}
				<ToastContainer position='top-right' autoClose={3000} hideProgressBar />
			</Box>
		</Box>
	);
};

export default Topbar;
