import { useEffect } from "react";
import axios from "axios";
import UserService from "../services/UserService";
import { useSnackbar } from "../contexts/snackbarContext";

const _axios = axios.create();

function useHttpService() {
  const snackbar = useSnackbar();

  const configure = () => {
    _axios.interceptors.request.use((config) => {
      if (UserService.isLoggedIn()) {
        const cb = () => {
          if (UserService.getUsername() === "efesto")
            config.headers.Authorization = `Bearer ${localStorage.getItem(
              "token"
            )}`;
          else
            config.headers.Authorization = `Bearer ${UserService.getToken()}`;
          return Promise.resolve(config);
        };
        return UserService.updateToken(cb);
      }
    });
  };

  const getAxiosClient = () => _axios;

  const getAPI = (
    apiUrl,
    tableData,
    setTableData,
    setRowCount,
    setIsLoading,
    setIsRefetching,
    setIsError
  ) => {
    if (apiUrl == null) return;

    if (!tableData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    _axios
      .get(apiUrl.href)
      .then((res) => {
        if (res.data.data != null) {
          setTableData(res.data.data);
          setRowCount(res.data.data.length);
        }
        else {
          setTableData([]);
          setRowCount(0);
        }
      })
      .catch((error) => {
        setIsError(true);
        console.error(error);
        return;
      });
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const getAPIStats = (
    apiUrl,
    data,
    setData,
    setRowCount,
    setIsLoading,
    setIsRefetching,
    setIsError
  ) => {
    if (apiUrl == null) return;

    if (!data) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    _axios
      .get(apiUrl.href)
      .then((res) => {
        if (res.data.data != null) {
          setData(res.data.data);
        }
        else {
          setData(null);
        }
      })
      .catch((error) => {
        setIsError(true);
        console.error(error);
        return;
      });
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const postAPI = (apiUrl, payload, setIsError, setFetchRequired) => {
    if (apiUrl == null) return;
    _axios
      .post(apiUrl.href, payload)
      .then((res) => {
        setFetchRequired(true);
      })
      .catch((error) => {
        setIsError(true);
        console.error(error);
        return;
      });
    setIsError(false);
  };

  const postRelay = (apiUrl, payload, setIsError, setRes) => {
    if (apiUrl == null) return;
    _axios
      .post(apiUrl.href, payload)
      .then((res) => {
        if (res.data != null) {
          setRes(res.data);
        }
        else {
          setRes([]);
        }
      })
      .catch((error) => {
        setIsError(true);
        console.error(error);
        return;
      });
    setIsError(false);
  };

  const postQueue = (apiUrl, payload, onSuccess, onError) => {
    if (apiUrl == null) return;
    _axios
      .post(apiUrl.href, payload)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError();
        return;
      });
  };

  const putAPI = (apiUrl, payload, setIsError, setFetchRequired) => {
    if (apiUrl == null) return;
    _axios
      .put(apiUrl, payload)
      .then((res) => {
        setFetchRequired(true);
      })
      .catch((error) => {
        setIsError(true);
        console.error(error);
        return;
      });
    setIsError(false);
  };

  const deleteAPI = (apiUrl, payload, setIsError, setFetchRequired) => {
    if (apiUrl == null) return;
    _axios
      .delete(apiUrl, {
        data: payload,
      })
      .then((res) => {
        setFetchRequired(true);
      })
      .catch((error) => {
        setIsError(true);
        console.error(error);
        return;
      });
    setIsError(false);
  };

  const downloadAPI = (url, callback, method = "get", body = {}) => {
    if (url == null) return;
    if (method === "get") {
      _axios
        .get(url, { responseType: "blob" })
        .then((res) => {
          if (res.data) callback(res.data);
          else callback(null);
        })
        .catch(function (error) {
          showSnackbarError(error);
        });
    }
    if (method === "post") {
      _axios
        .post(url, body, { responseType: "blob" })
        .then((res) => {
          if (res.data) callback(res.data);
          else callback(null);
        })
        .catch(function (error) {
          showSnackbarError(error);
        });
    }
  };

  const checkIfDataViolatesConstraints = (message) => {
    if (message.includes("violates foreign key constraint")) {
      const table = message.split("on table")[2];
      console.log(table);
      if (table.includes("NetworkSliceSubnet"))
        return "Cannot delete this element because is used from a Network Slice Subnet";
      if (table.includes("NetworkSlice"))
        return "Cannot delete this element because is used from a Network Slice";
      if (table.includes("NetworkFunction"))
        return "Cannot delete this element because is used from a Network Function";
      if (table.includes("RANSliceSubnetProfileInstance"))
        return "Cannot delete this element because is used from a Network Slice Subnet";
      if (table.includes("ServiceProfileInstance"))
        return "Cannot delete this element because is used from a Network Slice";
    }
    console.log(message);
    if (message.includes("<null>")) {
      return message
        .replace("unknown", "")
        .replace(":", "")
        .replace("<null>", "field is required");
    }
    return message;
  };

  const showSnackbarError = (error) => {
    console.error(error);
    if (error?.response?.status && error?.response?.data?.message) {
      error.response.data.message = checkIfDataViolatesConstraints(
        error.response.data.message
      );
      snackbar.setSnackbarInfo({
        status: "error",
        message: `Error ${error.response.status}: ${error.response.data.message}`,
      });
    } else if (error.toString().includes("Network Error")) {
      snackbar.setSnackbarInfo({
        status: "error",
        message: `A network error occurred. Please check your connection.`,
      });
    } else if (error?.response?.status && error?.response?.data?.message) {
      snackbar.setSnackbarInfo({
        status: "error",
        message: `Error ${error.response.status}: ${error.response.data.message}`,
      });
    } else if (!error.message.includes(`(reading 'protocol')`)) {
      snackbar.setSnackbarInfo({
        status: "error",
        message: `An error occurred: please report it to the administrator.`,
      });
    }
  };

  useEffect(() => {
    configure();
  }, []);

  return {
    getAPI,
    getAPIStats,
    postRelay,
    postAPI,
    postQueue,
    deleteAPI,
    putAPI,
    downloadAPI,
    getAxiosClient,
    configure,
  };
}

export default useHttpService;
