import { Box, Typography } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import useHttpService from '../../customHooks/useHttpService.js';
import DataTable from '../../components/DataTable/DataTable';

const PlmnInfo = () => {
	const getCombo = () => {
		return [
			{ text: 'a', value: '1' },
			{ text: 'b', value: '2' },
			{ text: 'c', value: '3' },
		];
	};

	const [plmnIdList, setPlmnIdList] = useState([]);
	const [plmnIdCombo, setPlmnIdCombo] = useState([]);
	const [snssaiList, setSnssaiList] = useState([]);
	const [snssaiCombo, setSnssaiCombo] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [rowCount, setRowCount] = useState(0);
	const [plmnIdFetchRequired, setPlmnIdFetchRequired] = useState(true);
	const [snssaiFetchRequired, setSnssaiFetchRequired] = useState(true);

	const httpService = useHttpService();

	const fetchPlmnIdList = () => {
		const apiPath = '/nf_fcaps/managed_object/PlmnId';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.getAPI(
			apiUrl,
			plmnIdList,
			setPlmnIdList,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	useEffect(() => {
		if (plmnIdFetchRequired) {
			fetchPlmnIdList();
			setPlmnIdFetchRequired(false);
		}
	}, [
		plmnIdList,
		plmnIdFetchRequired,
		setPlmnIdFetchRequired,
		setPlmnIdList,
		plmnIdCombo,
		setPlmnIdCombo,
		setRowCount,
		setIsLoading,
		setIsRefetching,
		setIsError,
	]);

	useEffect(() => {
		if (plmnIdList) {
			setPlmnIdCombo(
				plmnIdList.map(({ id, userLabel }) => ({ value: id, text: userLabel }))
			);
		}
	}, [plmnIdList, setPlmnIdCombo]);

	const fetchSnssaiList = () => {
		const apiPath = '/nf_fcaps/managed_object/Snssai';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.getAPI(
			apiUrl,
			snssaiList,
			setSnssaiList,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	useEffect(() => {
		if (snssaiFetchRequired) {
			fetchSnssaiList();
			setSnssaiFetchRequired(false);
		}
	}, [
		snssaiList,
		snssaiFetchRequired,
		setSnssaiFetchRequired,
		setSnssaiList,
		snssaiCombo,
		setSnssaiCombo,
		setRowCount,
		setIsLoading,
		setIsRefetching,
		setIsError,
	]);

	useEffect(() => {
		if (snssaiList) {
			setSnssaiCombo(
				snssaiList.map(({ id, userLabel }) => ({ value: id, text: userLabel }))
			);
		}
	}, [snssaiList, setSnssaiCombo]);

	const mapCombo = (jsonData, value) => {
		const item = jsonData.find((data) => data.value === value);
		return item ? item.text : value;
	};

	const columns = useMemo(
		() => [
			{
				accessorKey: 'id',
				header: 'ID',
				enableColumnOrdering: false,
				enableEditing: false,
				enableSorting: false,
				enableClickToCopy: true,
				size: 200,
				isInput: false,
				isVisible: true,
			},
			{
				accessorKey: 'userLabel',
				header: 'Name',
				size: 100,
				isInput: true,
				enableHiding: true,
				isVisible: true,
			},
			{
				accessorKey: 'PlmnId_id',
				header: 'PLMNID',
				size: 60,
				enableEditing: false,
				isInput: true,
				editVariant: 'select',
				editSelectOptions: plmnIdCombo,
				isVisible: true,
				Cell: ({ cell }) => mapCombo(plmnIdCombo, cell.getValue()),
			},
			{
				accessorKey: 'Snssai_id',
				header: 'SNSSAI',
				size: 60,
				enableEditing: false,
				isInput: true,
				editVariant: 'select',
				editSelectOptions: snssaiCombo,
				isVisible: true,
				Cell: ({ cell }) => mapCombo(snssaiCombo, cell.getValue()),
			},
		],
		[mapCombo, plmnIdCombo, snssaiCombo]
	);

	return (
		<Box m='20px'>
			<Header
				title='PLMNInfo'
				subtitle='List of Public Land Mobile Network Information (PLMNInfo)'
			/>
			<DataTable
				apiPath={{
					get: '/nf_fcaps/managed_object/PlmnInfo',
					post: '/nf_fcaps/managed_object/PlmnInfo',
					put: '/nf_fcaps/managed_object/PlmnInfo',
					delete: '/nf_fcaps/managed_object/PlmnInfo',
				}}
				columns={columns}
				createItemName='PLMNInfo'
				showCreateButton={true}
				showEditButton={true}
				showDeleteButton={true}
				showDownloadButton={false}
				showDownloadAllButton={false}
				showDiscoveryButton={false}
				DetailPanel={DetailPanel}
			/>
		</Box>
	);
};

export const DetailPanel = ({ row }) => {
	return (
		<Box
			sx={{
				display: 'grid',
				margin: 'auto',
				gridTemplateColumns: '0.1fr 0.1fr',
				width: '100%',
			}}>
			<Typography>MCC: {row.original.mcc}</Typography>
			<Typography>MNC: {row.original.mnc}</Typography>
			<Typography>SST: {row.original.sst}</Typography>
			<Typography>SD: {row.original.sd}</Typography>
		</Box>
	);
};

export default PlmnInfo;
