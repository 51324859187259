import React, { createContext, useRef, useEffect, useContext } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { buildChathubURL } from '../utils';
import { v4 as uuidv4 } from 'uuid';

const SignalRContext = createContext();

export const SignalRProvider = ({ children }) => {
	const messageCallbacksRef = useRef([]);

	useEffect(() => {
		const connection = new HubConnectionBuilder()
			.withUrl(buildChathubURL().href)
			.withAutomaticReconnect()
			.build();

		connection
			.start()
			.then((result) => {
				console.log('SignalR Provider connected!');

				connection.on('ReceiveMessage', (user, message) => {
					if (message.startsWith('(nssmfGroup) ')) {
						message = message.replace('(nssmfGroup) ', '');
						const newMessage = JSON.parse(message).content;
						handleMessage(newMessage);
					}
				});

				connection.invoke('JoinGroup', `reactuser-${uuidv4()}`, 'nssmfGroup');
			})
			.catch((e) => console.log('Connection failed: ', e));

		return () => {
			connection.stop();
		};
	}, []);

	const addMessageCallback = (callback) => {
		messageCallbacksRef.current.push(callback);
	};

	const removeMessageCallback = (callback) => {
		messageCallbacksRef.current = messageCallbacksRef.current.filter(
			(cb) => cb !== callback
		);
	};

	const handleMessage = (message) => {
		messageCallbacksRef.current.forEach((callback) => {
			if (typeof callback === 'function') {
				callback(message);
			}
		});
	};

	return (
		<SignalRContext.Provider
			value={{ addMessageCallback, removeMessageCallback }}
		>
			{children}
		</SignalRContext.Provider>
	);
};

export const useSignalR = () => useContext(SignalRContext);
