import {
	useTheme,
	Box,
	Button,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import Header from '../../components/Header/Header';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import useHttpService from '../../customHooks/useHttpService.js';
import { GetApp } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { useLocation } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { tokens } from '../../theme';
import * as XLSX from 'xlsx';
import TableRowsIcon from '@mui/icons-material/TableRows';

const Vendors = () => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const location = useLocation();
	const tableInstanceRef = useRef(null);

	// main data table
	const [tableData, setTableData] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState('');
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const fetchTableData = () => {
		const apiPath = '/nf_fcaps/managed_object/Vendor';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		//apiUrl.searchParams.set("size", `${pagination.pageSize}`);
		//apiUrl.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
		//apiUrl.searchParams.set("globalFilter", globalFilter ?? "");
		//apiUrl.searchParams.set("sorting", JSON.stringify(sorting ?? []));

		httpService.getAPI(
			apiUrl,
			tableData,
			setTableData,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	const getAlert = () => {
		if (isError) {
			return {
				color: 'error',
				children: 'Error loading data',
			};
		}

		return undefined;
	};

	const minifyID = (ID) => {
		return ID.split('-')[0] + ' ' + '\u00B7'.repeat(3);
	};

	useEffect(() => {
		if (fetchRequired) {
			fetchTableData();
			setFetchRequired(false);
		}
	}, [fetchRequired]);

	useEffect(() => {
		if (location.state && location.state.vendorID) {
			tableInstanceRef.current.setColumnFilters((prev) => [
				...prev,
				{ id: 'id', value: location.state.vendorID },
			]);
		}
	}, [location]);

	const [open, setOpen] = useState(false);
	const [nfTypes, setNfTypes] = useState([]);

	const handleClickOpen = (content) => {
		setNfTypes(content);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleDownloadRow = (row) => {
		const apiData = row.original;

		const modifiedRow = Object.entries(apiData).reduce((acc, [key, value]) => {
			acc[key] = Array.isArray(value) ? JSON.stringify(value) : value;
			return acc;
		}, {});

		const ws = XLSX.utils.json_to_sheet([modifiedRow]);
		const csvOutput = XLSX.utils.sheet_to_csv(ws);

		const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(blob, `${row.id}.csv`);
		} else {
			const link = document.createElement('a');
			if (link.download !== undefined) {
				const url = URL.createObjectURL(blob);
				link.setAttribute('href', url);
				link.setAttribute('download', `${row.id}.csv`);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	};

	const handleDownloadAll = () => {
		const csvRows = [Object.keys(tableData[0])];

		tableData.forEach((row) => {
			const modifiedRow = { ...row };
			for (const [key, value] of Object.entries(row)) {
				if (Array.isArray(value)) {
					modifiedRow[key] = JSON.stringify(value);
				}
			}
			csvRows.push(Object.values(modifiedRow));
		});

		const csvContent = csvRows
			.map((row) => row.map(escapeCsvValue).join(','))
			.join('\n');

		const blob = new Blob([csvContent], {
			type: 'text/csv;charset=utf-8;',
		});

		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(blob, 'AllRowsData.csv');
		} else {
			const link = document.createElement('a');
			if (link.download !== undefined) {
				const url = URL.createObjectURL(blob);
				link.setAttribute('href', url);
				link.setAttribute('download', 'AllRowsData.csv');
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	};

	const escapeCsvValue = (value) => {
		if (value.includes(',') || value.includes('"')) {
			return `"${value.replace(/"/g, '""')}"`;
		}
		return value;
	};

	const columns = useMemo(() => [
		{
			accessorKey: 'id',
			header: 'ID',
			size: 200,
			enableColumnOrdering: false,
			enableEditing: false,
			enableSorting: false,
			enableClickToCopy: true,
			isInput: false,
			Cell: ({ row }) => (
				<Tooltip title={row.original.id} arrow>
					<Typography variant='h6' color='primary'>
						{minifyID(row.original.id)}
					</Typography>
				</Tooltip>
			),
		},

		{
			accessorKey: 'name',
			header: 'Name',
			size: 100,
			isInput: false,
			enableEditing: false,
		},
		{
			accessorKey: 'Plugin',
			header: 'Plugin',
			size: 100,
			isInput: false,
			enableEditing: false,
		},
		{
			header: 'NF Types',
			accessor: 'actions',
			columnDefType: 'display',
			isInput: false,
			Cell: ({ row }) => (
				<Box sx={{ display: 'flex', gap: '1rem' }}>
					<Tooltip arrow placement='right' title='NF Types'>
						<IconButton onClick={() => handleClickOpen(row.original.nfTypes)}>
							<TableRowsIcon />
						</IconButton>
					</Tooltip>
				</Box>
			),
		},
	]);

	return (
		<Box m='20px'>
			<Header title='Vendors' subtitle={'List of Vendors'} />

			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableStickyHeader
				muiTableContainerProps={{ sx: { maxHeight: '55vh' } }}
				displayColumnDefOptions={{
					'mrt-row-actions': {
						muiTableHeadCellProps: {
							align: 'left',
							sx: {
								fontWeight: 'bold',
								color: colors.grey[100],
								typography: 'h5',
							},
						},
						muiTableBodyCellProps: {
							align: 'left',
						},
						size: 60,
					},
				}}
				positionActionsColumn='last'
				columns={columns}
				data={tableData}
				getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: true,
					density: 'compact',
				}}
				enableDensityToggle={false}
				muiTableHeadCellFilterTextFieldProps={{
					sx: { m: '0.3rem 0', width: '100%' },
				}}
				muiTableHeadCellProps={{
					align: 'left',
					sx: {
						fontWeight: 'bold',
						color: colors.grey[100],
						typography: 'h5',
					},
				}}
				muiTableBodyCellProps={{
					align: 'left',
					sx: {
						color: colors.grey[100],
						typography: 'h6',
					},
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				rowCount={rowCount}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableColumnOrdering={false}
				enableHiding={false}
				enableRowActions
				renderRowActionMenuItems={({ row, table }) => [
					<MenuItem key='export' onClick={() => handleDownloadRow(row)}>
						<ListItemIcon>
							<GetApp fontSize='large' />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: 'h6' }}
							primary='Export'
						/>
					</MenuItem>,
				]}
				renderTopToolbarCustomActions={() => (
					<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
						<Button
							color='secondary'
							onClick={handleDownloadAll}
							variant='contained'
							startIcon={<GetApp sx={{ color: '#FFFFFF' }} />}>
							<Typography variant='h7' sx={{ color: '#FFFFFF' }}>
								Export All Data (.csv)
							</Typography>
						</Button>
					</Box>
				)}
			/>
			<Dialog maxWidth='lg' open={open}>
				<DialogTitle>NF Types</DialogTitle>
				<DialogContent>
					<TableContainer
						component={Paper}
						sx={{
							'&::-webkit-scrollbar': {
								width: '0.4em',
							},
							'&::-webkit-scrollbar-track': {
								boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#6486c2',
								outline: '1px solid slategrey',
								'&:hover': {
									backgroundColor: '#5A7BB0',
								},
								'&:active': {
									backgroundColor: '#4D6E9E',
								},
							},
						}}>
						<Table
							sx={{ minWidth: 650 }}
							size='small'
							aria-label='a dense table'>
							<TableHead>
								<TableRow>
									<TableCell align='center'>NF Domain</TableCell>
									<TableCell align='center'>NF Technology</TableCell>
									<TableCell align='center'>NF Class</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{nfTypes.map((nfType, i) => (
									<TableRow key={i}>
										<TableCell component='th' scope='row' align='center'>
											{nfType.nfDomain}
										</TableCell>
										<TableCell align='center'>{nfType.nfTechnology}</TableCell>
										<TableCell align='center'>{nfType.nfClass}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary' autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default Vendors;
