import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header/Header";
import { PieChart } from '@mui/x-charts/PieChart';
import React, { useMemo, useState, useEffect, useCallback } from "react";
import useHttpService from "../../customHooks/useHttpService.js";
import { ResponsivePie } from '@nivo/pie'
import { tokens } from "../../theme";
import StatBox from "../../components/StatBox/StatBox";
import WebAssetOutlinedIcon from '@mui/icons-material/WebAssetOutlined';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';


const Dashboard = () => {

    const httpService = useHttpService();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // main data table
    const [stats, setStats] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [fetchRequired, setFetchRequired] = useState(true);
    const [rowCount, setRowCount] = useState(0);

    const [nfStats, setNfStats] = useState([]);
    const [mnStats, setMnStats] = useState([]);
    const [nsStats, setNsStats] = useState([]);
    const [numberNFs, setNumberNFs] = useState(0);
    const [numberMNs, setNumberMNs] = useState(0);
    const [numberMEs, setNumberMEs] = useState(0);
    const [numberEMs, setNumberEMs] = useState(0);
    const [numberNSs, setNumberNSs] = useState(0);

    const fetchStats = () => {
        const apiPath = "/nf_fcaps/managed_object/dashboard_stats";
        const apiUrl = new URL(
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
                : process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_URL
                : process.env.REACT_APP_DEV_API_BASE_URL
        );
        //apiUrl.searchParams.set("size", `${pagination.pageSize}`);
        //apiUrl.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        //apiUrl.searchParams.set("globalFilter", globalFilter ?? "");
        //apiUrl.searchParams.set("sorting", JSON.stringify(sorting ?? []));

        httpService.getAPIStats(
            apiUrl,
            stats,
            setStats,
            setRowCount,
            setIsLoading,
            setIsRefetching,
            setIsError
        );
    };

    useEffect(() => {
        if (fetchRequired) {
            fetchStats();
            setFetchRequired(false);
        }
    }, [fetchRequired]);

    useEffect(() => {
        if (stats) {
            setNumberNFs(("nf_tot") in stats ? stats["nf_tot"] : 0);
            setNumberMNs(("mn_tot") in stats ? stats["mn_tot"] : 0);
            setNumberEMs(("em_tot") in stats ? stats["em_tot"] : 0);
            setNumberMEs(("me_tot") in stats ? stats["me_tot"] : 0);
            setNumberNSs(("ns_tot") in stats ? stats["ns_tot"] : 0);
            if ("states" in stats)
            {
                setNfStats([
                    { id: "LOCKED_DISABLED", value: ('LOCKED-DISABLED' in stats.states) ? stats.states['LOCKED-DISABLED'] : 0, label: "LOCKED_DISABLED", color: "#ff8c00" },
                    { id: "LOCKED-ENABLED", value: ('LOCKED-ENABLED' in stats.states) ? stats.states['LOCKED-ENABLED'] : 0, label: "LOCKED-ENABLED", color: "#ffff1a" },
                    { id: "UNLOCKED-DISABLED", value: ('UNLOCKED-DISABLED' in stats.states) ? stats.states['UNLOCKED-DISABLED'] : 0, label: "UNLOCKED-DISABLED", color: "#e63900" },
                    { id: "UNLOCKED-ENABLED", value: ('UNLOCKED-ENABLED' in stats.states) ? stats.states['UNLOCKED-ENABLED'] : 0, label: "UNLOCKED-ENABLED", color: "#00b33c" },
                ]);
            }
            setMnStats([
                { id: "LOCKED", value: ('mn_locked' in stats) ? stats['mn_locked'] : 0, label: "LOCKED", color: "#ffff1a" },
                { id: "UNLOCKED", value: ('mn_unlocked' in stats) ? stats['mn_unlocked'] : 0, label: "UNLOCKED", color: "#00b33c" },
            ]);
            setNsStats([
                { id: "Not Instantiated", value: ('ns_notinstantiated' in stats) ? stats['ns_notinstantiated'] : 0, label: "Not Instantiated", color: "#ffff1a" },
                { id: "Instantiated", value: ('ns_instantiated' in stats) ? stats['ns_instantiated'] : 0, label: "Instantiated", color: "#00b33c" },
            ]);
        }
    }, [stats]);

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Header title="DASHBOARD" subtitle="Welcome" />
            </Box>
            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="240px"
                gap="20px"
            >
                {/* ROW 1 */}
                <Box
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        total={numberNFs}
                        title="Network Functions"
                        icon={
                            <WebAssetOutlinedIcon
                                sx={{
                                    color: colors.skyAccent[500],
                                    fontSize: "26px",
                                }}
                            />
                        }
                        data={nfStats}
                        linkTo="/nfs"
                    />
                </Box>
                <Box
                    gridColumn="span 6"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        total={numberMNs}
                        title="Management Nodes"
                        icon={
                            <RoomPreferencesIcon
                                sx={{
                                    color: colors.skyAccent[500],
                                    fontSize: "26px",
                                }}
                            />
                        }
                        data={mnStats}
                        linkTo="/mnem"
                    />
                </Box>
                <Box
                    gridColumn="span 6"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        total={numberNSs}
                        title="Network Services"
                        icon={
                            <WebAssetOutlinedIcon
                                sx={{
                                    color: colors.skyAccent[500],
                                    fontSize: "26px",
                                }}
                            />
                        }
                        data={nsStats}
                        linkTo="/ns"
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default Dashboard;