import {
    useTheme,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    InputLabel,
    FormControl,
    Typography,
    Autocomplete,
    Grid,
    Divider,
} from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import Header from "../../components/Header/Header";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import useHttpService from "../../customHooks/useHttpService.js";
import ElementManagers from "../elementManagers/ElementManagers";
import { ToastContainer, toast } from "react-toastify";
import { Delete, Edit, ContentCopy, Refresh } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable } from "material-react-table";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { tokens } from "../../theme";
import EditDialog from "../../components/EditDialog/EditDialog";
import { Link, useNavigate } from "react-router-dom";
import { nfcMockData } from "../../data/nfcMockData";

const NetworkServices = () => {
    const httpService = useHttpService();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const toManagementNodesPage = (mnName, mnId) => {
        navigate("/mnem", { state: { mnName: mnName, mnId: mnId } });
    };

    // main data table
    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [fetchRequired, setFetchRequired] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [isErrorUpdate, setIsErrorUpdate] = useState(false);
    const [isErrorCreate, setIsErrorCreate] = useState(false);
    const [isErrorDelete, setIsErrorDelete] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const [confirmDiscoveryOpen, setConfirmDiscoveryOpen] = useState(false);
    const [discoveryRow, setDiscoveryRow] = useState(null);

    const fetchTableData = () => {
        const apiPath = "/nf_fcaps/managed_object/NetworkServiceInstance";
        const apiUrl = new URL(
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
                : process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_URL
                : process.env.REACT_APP_DEV_API_BASE_URL
        );
        //apiUrl.searchParams.set("size", `${pagination.pageSize}`);
        //apiUrl.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        //apiUrl.searchParams.set("globalFilter", globalFilter ?? "");
        //apiUrl.searchParams.set("sorting", JSON.stringify(sorting ?? []));

        httpService.getAPI(
            apiUrl,
            tableData,
            setTableData,
            setRowCount,
            setIsLoading,
            setIsRefetching,
            setIsError
        );
    };

    const onDeleteSuccess = () => {
		toast.success("Network Service Instance Termination task has been sent successfully");
	};

	const onDeleteError = () => {
		toast.error("Error occurred while passing Network Service Instance Termination task");
	};

    const handleDeleteRow = (row) => {
        if (
            !window.confirm(
                `Are you sure you want to terminate ${row.getValue(
                    "instanceName"
                )}?\nThe instance will be terminated and all the related objects will be deleted`
            )
        ) {
            return;
        }

        const data = {
            Name: "terminateNSInstance",
            Command: "terminateNSInstance",
            ns_local_id: row.original.id,
            moi_orig: row.original.attr ? row.original.attr.plugin : "",
        };

        console.log(data);

        const apiPath = '/celery/push/OPERMOI/terminateNSInstance';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_QUEUE_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_QUEUE_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

        httpService.postQueue(apiUrl, data, onDeleteSuccess, onDeleteError);
    };

    const getAlert = () => {
        if (isError) {
            return {
                color: "error",
                children: "Error loading data",
            };
        } else if (isErrorCreate) {
            return {
                color: "error",
                children: "Error creating new item",
            };
        } else if (isErrorUpdate) {
            return {
                color: "error",
                children: "Error updating the item",
            };
        } else if (isErrorDelete) {
            return {
                color: "error",
                children: "Error deleting the item",
            };
        }

        return undefined;
    };

    const validateRequired = (value) => !!value.length;

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const minifyID = (ID) => {
        return ID.split("-")[0] + " " + "\u00B7".repeat(3);
    };

    useEffect(() => {
        if (fetchRequired) {
            fetchTableData();
            setFetchRequired(false);
        }
    }, [fetchRequired]);

    useEffect(() => {
        if (isErrorDelete) {
            const timeId = setTimeout(() => {
                setIsErrorDelete(false);
            }, 5000);

            return () => {
                clearTimeout(timeId);
            };
        }
    }, [isErrorDelete]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: false,
                enableClickToCopy: true,
                size: 60,
                isInput: false,
                Cell: ({ row }) => (
                    <Tooltip title={row.original.id} arrow>
                        <Typography variant="h6" color="primary">
                            {row.original.id && minifyID(row.original.id)}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellEditTextFieldProps: {
                    type: "string",
                    variant: "outlined",
                },
            },
            {
                accessorKey: "instanceId",
                header: "Instance ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: false,
                enableClickToCopy: true,
                size: 60,
                isInput: false,
                Cell: ({ row }) => (
                    <Tooltip title={row.original.instanceId} arrow>
                        <Typography variant="h6" color="primary">
                            {row.original.instanceId &&
                                minifyID(row.original.instanceId)}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellEditTextFieldProps: {
                    type: "string",
                    variant: "outlined",
                },
            },
            {
                accessorKey: "instanceName",
                header: "Instance Name",
                size: 60,
                isInput: false,
            },
            {
                accessorKey: "nsdId",
                header: "NSD ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: false,
                enableClickToCopy: true,
                size: 60,
                isInput: false,
                Cell: ({ row }) => (
                    <Tooltip title={row.original.nsdId} arrow>
                        <Typography variant="h6" color="primary">
                            {row.original.nsdId && minifyID(row.original.nsdId)}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellEditTextFieldProps: {
                    type: "string",
                    variant: "outlined",
                },
            },
            {
                accessorKey: "status",
                header: "Status",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: false,
                size: 60,
                isInput: false,
                muiTableBodyCellEditTextFieldProps: {
                    type: "string",
                    variant: "outlined",
                },
            },
            {
                accessorKey: "ManagementNode",
                header: "Management Node",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: false,
                size: 60,
                isInput: false,
                muiTableBodyCellEditTextFieldProps: {
                    type: "string",
                    variant: "outlined",
                },
                Cell: ({ row }) => (
					<Tooltip title='Click to open' arrow placement='top-start'>
						<Typography
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								toManagementNodesPage(row.original.ManagementNode, row.original.ManagementNode_id);
							}}
							variant='h6'
							color='primary'>
							{row.original.ManagementNode}
						</Typography>
					</Tooltip>
				),
            },
        ],
        []
    );
    return (
        <Box m="20px">
            <Header
                title="Network Services"
                subtitle="List of Network Services (NS)"
            />

            <MaterialReactTable
                sx={{
                    "&::-webkit-scrollbar": {
                        width: "0.4em",
                    },
                    "&::-webkit-scrollbar-track": {
                        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#6486c2",
                        outline: "1px solid slategrey",
                        "&:hover": {
                            backgroundColor: "#5A7BB0",
                        },
                        "&:active": {
                            backgroundColor: "#4D6E9E",
                        },
                    },
                }}
                enableStickyHeader
                muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
                displayColumnDefOptions={{
                    "mrt-row-actions": {
                        muiTableHeadCellProps: {
                            align: "left",
                            sx: {
                                fontWeight: "bold",
                                color: colors.grey[100],
                                typography: "h5",
                            },
                        },
                        muiTableBodyCellProps: {
                            align: "left",
                        },
                        size: 60,
                    },
                }}
                positionActionsColumn="last"
                columns={columns}
                data={tableData}
                getRowId={(row) => row.id}
                initialState={{
                    showColumnFilters: true,
                    showGlobalFilter: true,
                    density: "compact",
                }}
                enableDensityToggle={false}
                muiTableHeadCellFilterTextFieldProps={{
                    sx: { m: "0.3rem 0", width: "100%" },
                }}
                muiTableHeadCellProps={{
                    align: "left",
                    sx: {
                        fontWeight: "bold",
                        color: colors.grey[100],
                        typography: "h5",
                    },
                }}
                muiTableBodyCellProps={{
                    align: "left",
                    sx: {
                        color: colors.grey[100],
                        typography: "h6",
                    },
                }}
                muiToolbarAlertBannerProps={getAlert()}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner:
                        isError ||
                        isErrorCreate ||
                        isErrorUpdate ||
                        isErrorDelete,
                    showProgressBars: isRefetching,
                    sorting,
                }}
                editingMode="modal"
                enableColumnOrdering={false}
                enableHiding={false}
                enableRowActions
                renderBottomToolbarCustomActions={() => {
                    return (
                        <div>
                            <Tooltip arrow title="Refresh">
                                <IconButton
                                    onClick={() => {
                                        setFetchRequired(true);
                                    }}
                                >
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                }}
                renderRowActionMenuItems={({ row, table }) => [
                    <MenuItem key="delete" disabled={row.original.status!='Running'} onClick={() => handleDeleteRow(row)}>
                        <ListItemIcon>
                            <Delete fontSize="large" color="error" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: "h6" }}
                            primary="Terminate"
                        />
                    </MenuItem>,
                ]}
                renderTopToolbarCustomActions={() => (
                    <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
                        <Button
                            color="secondary"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                            startIcon={
                                <AddCircleOutlineIcon
                                    sx={{ color: "#FFFFFF" }}
                                />
                            }
                        >
                            <Typography variant="h7" sx={{ color: "#FFFFFF" }}>
                                Create New Network Service Instance
                            </Typography>
                        </Button>
                    </Box>
                )}
            />

            <CreateNewItemModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                createItemName={"Network Service Instance"}
            />
            <Box m="20px">
                {/* ... */}
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                />
            </Box>
        </Box>
    );
};

export const CreateNewItemModal = ({ open, onClose, createItemName }) => {
    const httpService = useHttpService();

    const [selectedPlugin, setSelectedPlugin] = useState(null);
    const [selectedNsd, setSelectedNsd] = useState(null);
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [selectedDatacenter, setSelectedDatacenter] = useState(null);
    const [applicationVariables, setApplicationVariables] = useState([]);

    const [sol011data, setSol011data] = useState([]);
    const [sol011FetchRequired, setSol011FetchRequired] = useState(true);
    const [sol011IsError, setSol011IsError] = useState(false);

    const [pluginList, setPluginList] = useState([]);
    const [pluginFetchRequired, setPluginFetchRequired] = useState(true);
    const [pluginIsError, setPluginIsError] = useState(false);
    const [pluginIsLoading, setPluginIsLoading] = useState(false);
    const [pluginIsRefetching, setPluginIsRefetching] = useState(false);
    const [pluginRowCount, setPluginRowCount] = useState(0);

    const fetchSol011data = () => {
        const apiUrl = new URL(
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_RELAY_BASE_PATH
                : process.env.REACT_APP_DEV_RELAY_BASE_PATH,
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_URL
                : process.env.REACT_APP_DEV_API_BASE_URL
        );
        const payload = {
            method: "GET",
            url: "##SOL_OPERATION_URL##/ns_descriptors",
        };
        httpService.postRelay(apiUrl, payload, setSol011IsError, setSol011data);
    };

    const fetchPluginList = () => {
        const apiPath = "/nf_fcaps/managed_object/Plugin";
        const apiUrl = new URL(
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
                : process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_URL
                : process.env.REACT_APP_DEV_API_BASE_URL
        );
        httpService.getAPI(
            apiUrl,
            pluginList,
            setPluginList,
            setPluginRowCount,
            setPluginIsLoading,
            setPluginIsRefetching,
            setPluginIsError
        );
    };

    useEffect(() => {
        if (sol011FetchRequired) {
            fetchSol011data();
            setSol011FetchRequired(false);
            //setSol011data(nfcMockData);
        }
    }, [sol011FetchRequired]);

    useEffect(() => {
        if (pluginFetchRequired) {
            fetchPluginList();
            setPluginFetchRequired(false);
        }
    }, [pluginFetchRequired]);

    const handlePluginChange = (event, value) => {
        setSelectedPlugin(value);
        setSelectedNsd(null);
        setSelectedApplication(null);
        setSelectedDatacenter(null);
        setApplicationVariables([]);
    };

    const handleNsdChange = (event, value) => {
        setSelectedNsd(value);
        setSelectedApplication(null);
        setSelectedDatacenter(null);
        setApplicationVariables([]);
    };

    const handleApplicationChange = (event, value) => {
        setSelectedApplication(value);
        setSelectedDatacenter(null);
        if (value) {
            setApplicationVariables([...value.application_variables]);
        }
    };

    const handleDatacenterChange = (event, value) => {
        setSelectedDatacenter(value);
    };

    const onCreateSuccess = () => {
		toast.success("Network Service Instance Creation task has been sent successfully");
	};

	const onCreateError = () => {
		toast.error("Error occurred while passing Network Service Instance Creation task");
	};

    const handleSubmit = (event) => {
        event.preventDefault();

        const updatedApplicationVariables = applicationVariables.map(
            (variable) => ({
                ...variable,
                service_id: selectedNsd.id,
                application_id: selectedApplication.id,
            })
        );

        const result = {
            Name: "createNSInstance",
            Command: "createNSInstance",
            nsd_id: selectedNsd.id,
            app_dc: [
                {
                    application_id: selectedApplication.id,
                    datacenter_id: selectedDatacenter.id,
                },
            ],
            app_vars: updatedApplicationVariables,
            moi_orig: selectedPlugin.name,
        };

        console.log(result);
        // TODO: Send 'result' to server or handle it as needed

        const apiPath = '/celery/push/OPERMOI/createNSInstance';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_QUEUE_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_QUEUE_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

        httpService.postQueue(apiUrl, result, onCreateSuccess, onCreateError);

        handleClose();
    };

    const handleVariableChange = (id) => (event) => {
        setApplicationVariables((prevVars) =>
            prevVars.map((variable) =>
                variable.id === id
                    ? { ...variable, var_value: event.target.value }
                    : variable
            )
        );
    };

    // const jsonData = nfcMockData;

    const handleClose = () => {
        setSelectedPlugin(null);
        setSelectedNsd(null);
        setSelectedApplication(null);
        setSelectedDatacenter(null);
        setApplicationVariables([]);
        onClose();
    };

    const handleCopy = () => {
        const myString =
            "http://nssmf:pwd123@nf-fcaps-be-notify-api.nf-fcaps-latest/eventListener";
        navigator.clipboard
            .writeText(myString)
            .then(() => {
                alert("Local Monitoring URL Copied to clipboard");
            })
            .catch((err) => {
                alert("Error in copying to clipboard: ", err);
            });
    };

    return (
        sol011data && (
            <div>
                <Dialog
                    open={open}
                    maxWidth="xl"
                    PaperProps={{
                        sx: {
                            width: "100%",
                            height: "80%",
                            maxWidth: "60%",
                            minHeight: "50%",
                        },
                    }}
                >
                    <DialogTitle textAlign="left" variant="h5">
                        {"Create New " + createItemName}
                        <Button
                            style={{ float: "right" }}
                            color="secondary"
                            variant="contained"
                            onClick={handleCopy}
                        >
                            Copy Local Monitoring URL
                        </Button>
                    </DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <Box m={2}>
                                {pluginList && (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2.5}>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    Select Plugin
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Box mb={2}>
                                                    <Autocomplete
                                                        options={pluginList}
                                                        getOptionLabel={(
                                                            option
                                                        ) => option.name}
                                                        onChange={
                                                            handlePluginChange
                                                        }
                                                        value={selectedPlugin}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Plugin"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box mb={2}>
                                            <Divider />
                                        </Box>
                                    </>
                                )}
                                {selectedPlugin && (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2.5}>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    Select NSD
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Box mb={2}>
                                                    <Autocomplete
                                                        options={sol011data}
                                                        getOptionLabel={(
                                                            option
                                                        ) => option.nsdName}
                                                        onChange={
                                                            handleNsdChange
                                                        }
                                                        value={selectedNsd}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="NSD Name"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box mb={2}>
                                            <Divider />
                                        </Box>
                                    </>
                                )}

                                {selectedNsd && (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2.5}>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    Select Application
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Box mb={2}>
                                                    <Autocomplete
                                                        options={
                                                            selectedNsd
                                                                .userDefinedData
                                                                .applications
                                                        }
                                                        getOptionLabel={(
                                                            option
                                                        ) => option.name}
                                                        onChange={
                                                            handleApplicationChange
                                                        }
                                                        value={
                                                            selectedApplication
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Application"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box mb={2}>
                                            <Divider />
                                        </Box>
                                    </>
                                )}

                                {selectedApplication && (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2.5}>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    Select Datacenter
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Box mb={2}>
                                                    <Autocomplete
                                                        options={
                                                            selectedApplication.datacenters
                                                        }
                                                        getOptionLabel={(
                                                            option
                                                        ) => option.name}
                                                        onChange={
                                                            handleDatacenterChange
                                                        }
                                                        value={
                                                            selectedDatacenter
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Datacenter"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box mb={2}>
                                            <Divider />
                                        </Box>
                                    </>
                                )}

                                {selectedDatacenter && (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2.5}>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    Application Variables
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Box mb={2}>
                                                    <FormControl fullWidth>
                                                        {applicationVariables.map(
                                                            (variable) => (
                                                                (variable.var_type != "hidden" &&
                                                                    <Box
                                                                        mb={2}
                                                                        key={
                                                                            variable.id
                                                                        }
                                                                    >
                                                                        <TextField
                                                                            label={
                                                                                variable.var_name
                                                                            }
                                                                            defaultValue={
                                                                                variable.var_value
                                                                            }
                                                                            fullWidth
                                                                            margin="normal"
                                                                            onChange={handleVariableChange(
                                                                                variable.id
                                                                            )}
                                                                        />
                                                                    </Box>)
                                                            )
                                                        )}
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box mb={2}>
                                            <Divider />
                                        </Box>
                                    </>
                                )}
                                <DialogActions sx={{ p: "1.25rem" }}>
                                    <Button
                                        onClick={handleClose}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        disabled={
                                            !(
                                                pluginList &&
                                                selectedNsd &&
                                                selectedApplication &&
                                                selectedDatacenter &&
                                                applicationVariables
                                            )
                                        }
                                    >
                                        Submit
                                    </Button>
                                </DialogActions>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    );
};
export default NetworkServices;
