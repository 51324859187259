import {
    useTheme,
    Box,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import Header from "../../components/Header/Header";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import EditDialog from "../../components/EditDialog/EditDialog";
import useHttpService from "../../customHooks/useHttpService.js";
import { Refresh } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable } from "material-react-table";
import { useLocation , useNavigate } from 'react-router-dom';
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DataObjectIcon from '@mui/icons-material/DataObject';
import { tokens } from "../../theme";
import { JsonViewer } from '@textea/json-viewer'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AlarmIcon from '@mui/icons-material/Alarm';

const NetworkFunctions = () => {
    const httpService = useHttpService();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
	const location = useLocation();
	const tableInstanceRef = useRef(null);
    const navigate = useNavigate();
	const toNotificationsPage = (objectInstance) => {
		navigate('/notifications', { state: { objectInstance: objectInstance } });
	};
    const toAlarmsPage = (objectInstance) => {
		navigate('/alarms', { state: { objectInstance: objectInstance } });
	};

    // main data table
    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isErrorUpdate, setIsErrorUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [fetchRequired, setFetchRequired] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [confirmDiscoveryOpen, setConfirmDiscoveryOpen] = useState(false);
	const [discoveryRow, setDiscoveryRow] = useState(null);

    const [modifyOpen, setModifyOpen] = useState(false);
	const [modifyRow, setModifyRow] = useState({});

    const [jsonOpen, setJsonOpen] = useState(false);
	const [jsonContent, setJsonContent] = useState(null);


    const fetchTableData = () => {
        const apiPath = "/nf_fcaps/managed_object/gui_NetworkFunction";
        const apiUrl = new URL(
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
                : process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_URL
                : process.env.REACT_APP_DEV_API_BASE_URL
        );
        //apiUrl.searchParams.set("size", `${pagination.pageSize}`);
        //apiUrl.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        //apiUrl.searchParams.set("globalFilter", globalFilter ?? "");
        //apiUrl.searchParams.set("sorting", JSON.stringify(sorting ?? []));

        httpService.getAPI(
            apiUrl,
            tableData,
            setTableData,
            setRowCount,
            setIsLoading,
            setIsRefetching,
            setIsError
        );
    };

    const getAlert = () => {
        if (isError) {
            return {
                color: "error",
                children: "Error loading data",
            };
        }

        return undefined;
    };

    const minifyID = (ID) => {
        return ID.split("-")[0] + " " + "\u00B7".repeat(3);
    };

    const handleCloseModify = () => {
		setModifyOpen(false);
	};

    const handleSaveModify = (values) => {
		console.log(values);
        const changedKeys = Object.keys(values).filter(
            (key) => values[key] !== modifyRow[key]
        );

        if (changedKeys.includes("me_userLabel")) {
            const index = changedKeys.indexOf('me_userLabel');
            if (index !== -1) {
                changedKeys.splice(index, 1);
            }
            updateMeName(values.ManagedElement_id, values.me_userLabel);
        } 
        if (changedKeys.includes("userLabel")) {
            const index = changedKeys.indexOf('userLabel');
            if (index !== -1) {
                changedKeys.splice(index, 1);
            }
            updateNfName(values.id, values.userLabel);
        }
        if (changedKeys.length > 0)
        {
            sendModifyTask(
                values.Plugin_name,
                values.ManagedElement_id,
                values.id,
                values.ManagementNode_id,
                {"administrativeState": values.administrativeState},
            );
        }
	};

    const updateMeName = (id, newName) => {
		const apiPath = "/nf_fcaps/managed_object/ManagedElement";
        const apiUrl = new URL(
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
                : process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_URL
                : process.env.REACT_APP_DEV_API_BASE_URL
        );

		const data = {
			id: id,
			userLabel: newName,
		};

		httpService.putAPI(apiUrl, data, setIsErrorUpdate, setFetchRequired);
	};

    const updateNfName = (id, newName) => {
		const apiPath = "/nf_fcaps/managed_object/NetworkFunction";
        const apiUrl = new URL(
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
                : process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_URL
                : process.env.REACT_APP_DEV_API_BASE_URL
        );

		const data = {
			id: id,
			userLabel: newName,
		};

		httpService.putAPI(apiUrl, data, setIsErrorUpdate, setFetchRequired);
	};

    const onModifySuccess = () => {
		toast.success('Modification task has been sent successfully');
	};

	const onModifyError = () => {
		toast.error('Error occurred while sending modification task');
	};

    const sendModifyTask = (pluginName, meId, nfId, mnId, modifyReq) => {

		const apiPath = "/celery/push/OPERMOI/modifyMOIattributes";
        const apiUrl = new URL(
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_QUEUE_BASE_PATH + apiPath
                : process.env.REACT_APP_DEV_QUEUE_BASE_PATH + apiPath,
            process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PROD_API_BASE_URL
                : process.env.REACT_APP_DEV_API_BASE_URL
        );

		const data = {
			Name: 'modifyMOIattributes',
			moi_orig: pluginName,
			moi_class: 'NSSI',
			moi_id: meId,
            nf_id: nfId,
            mn_id: mnId,
			Command: 'modifyMOIattributes',
            modify_req: modifyReq,
		};

		httpService.postQueue(apiUrl, data, onModifySuccess, onModifyError);
	};

    const handleCloseJson = () => {
		setJsonOpen(false);
	};

    const onDiscoverySuccess = () => {
		toast.success('Discovery action passed successfully');
	};

	const onDiscoveryError = () => {
		toast.error('Error occurred while passing the discovery action');
	};

    const handleDiscovery = () => {

		if (!discoveryRow) return;

		const apiPath = '/celery/push/OPERMOI/getMOIattributes';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_QUEUE_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_QUEUE_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);

		const data = {
			Name: 'getMOIattributes',
			moi_orig: discoveryRow.original.Plugin_name,
			moi_class: 'NSSI',
			moi_id: discoveryRow.original.ManagedElement_id,
			ne_id: discoveryRow.original.ManagementNode_id,
			Command: 'getMOIattributes',
		};

		httpService.postQueue(apiUrl, data, onDiscoverySuccess, onDiscoveryError);
	};

    useEffect(() => {
        if (fetchRequired) {
            fetchTableData();
            setFetchRequired(false);
        }
    }, [fetchRequired]);

    useEffect(() => {
		if (location.state && location.state.mnName) {
			tableInstanceRef.current.setColumnFilters((prev) => [
				...prev,
				{ id: 'mn_userLabel', value: location.state.mnName },
			]);
		}
	}, [location]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                size: 80,
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: false,
                enableClickToCopy: true,
                isInput: false,
                Cell: ({ row }) => (
                    <Tooltip title={row.original.id} arrow>
                        <Typography variant="h6" color="primary">
                            {minifyID(row.original.id)}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                accessorKey: "userLabel",
                header: "Name",
                size: 220,
                isInput: false,
                enableEditing: false,
            },
            {
                accessorKey: "functionType",
                header: "Function Type",
                size: 220,
                enableEditing: false,
                isInput: false,
            },
            {
                accessorKey: "domain",
                header: "Domain",
                size: 110,
                enableEditing: false,
                isInput: false,
                filterSelectOptions: [
                    { text: "RAN", value: "RAN" },
                    { text: "CN", value: "CN" },
                    { text: "TN", value: "TN" },
                ],
                filterVariant: "multi-select",
                muiTableBodyCellProps: {
                    align: "left",
                },
            },
            {
                accessorKey: "technology",
                header: "Technology",
                size: 110,
                enableEditing: false,
                isInput: false,
                filterFn: 'equals',
                filterSelectOptions: [
                    { text: "5G", value: "5G" },
                    { text: "4G", value: "4G" },
                ],
                filterVariant: "select",
                muiTableBodyCellProps: {
                    align: "left",
                },
            },
            {
                accessorKey: "administrativeState",
                header: "Administrative State",
                size: 220,
                enableEditing: false,
                isInput: false,
                muiTableBodyCellProps: {
                    align: "left",
                },
                filterFn: 'equals',
                filterSelectOptions: [
                    { text: "LOCKED", value: "LOCKED" },
                    { text: "UNLOCKED", value: "UNLOCKED" },
                ],
                filterVariant: "select",
            },
            {
                accessorKey: "operationalState",
                header: "Operational State",
                size: 220,
                enableEditing: false,
                isInput: false,
                muiTableBodyCellProps: {
                    align: "left",
                },
                filterFn: 'equals',
                filterSelectOptions: [
                    { text: "ENABLED", value: "ENABLED" },
                    { text: "DISABLED", value: "DISABLED" },
                ],
                filterVariant: "select",
            },
            {
                accessorKey: "me_userLabel",
                header: "Managed Element",
                maxSize: 220,
                enableEditing: false,
                isInput: false,
                muiTableBodyCellProps: {
                    align: "left",
                },
            },
            {
                accessorKey: "mn_userLabel",
                header: "Management Node",
                maxSize: 220,
                enableEditing: false,
                isInput: false,
                muiTableBodyCellProps: {
                    align: "left",
                },
            },
        ],
        []
    );

    const editSchema = [
        {
            accessorKey: "ManagedElement_id",
            label: "ID",
            disabled: true,
            type: "text",
            section: "ME",
            width: "300px",
        },
        {
            accessorKey: "me_origId",
            label: "Original ID",
            disabled: true,
            type: "text",
            section: "ME",
            width: "200px",
        },
        {
            accessorKey: "me_userLabel",
            label: "Name",
            disabled: false,
            type: "text",
            section: "ME",
            width: "200px",
        },
        {
            accessorKey: "id",
            label: "ID",
            disabled: true,
            type: "text",
            section: "NF",
            width: "300px",
        },
        {
            accessorKey: "origId",
            label: "Original ID",
            disabled: true,
            type: "text",
            section: "NF",
            width: "200px",
        },
        {
            accessorKey: "userLabel",
            label: "Name",
            disabled: false,
            type: "text",
            section: "NF",
            width: "200px",
        },
        {
            accessorKey: "administrativeState",
            label: "Administrative State",
            disabled: false,
            type: "select",
            options: ["LOCKED", "UNLOCKED"],
            section: "NF",
            width: "200px",
        },
    ];
      
      

    return (
        <Box m="20px">
            <Header
                title="Network Functions"
                subtitle={"List of Network Functions"}
            />
            <MaterialReactTable
                tableInstanceRef={tableInstanceRef}
                enableStickyHeader
                muiTableContainerProps={{ sx: { maxHeight: "55vh" } }}
                displayColumnDefOptions={{
                    "mrt-row-actions": {
                        muiTableHeadCellProps: {
                            align: "left",
                            sx: {
                                fontWeight: "bold",
                                color: colors.grey[100],
                                typography: "h5",
                            },
                        },
                        muiTableBodyCellProps: {
                            align: "left",
                        },
                        size: 40,
                    },
                }}
                positionActionsColumn="last"
                columns={columns}
                data={tableData}
                getRowId={(row) => row.id}
                initialState={{
                    showColumnFilters: true,
                    showGlobalFilter: true,
                    density: "compact",
                }}
                enableDensityToggle={false}
                muiTableHeadCellProps={{
                    align: "left",
                    sx: {
                        fontWeight: "bold",
                        color: colors.grey[100],
                        typography: "h5",
                    },
                }}
                muiTableBodyCellProps={{
                    align: "left",
                    sx: {
                        color: colors.grey[100],
                        typography: "h6",
                    },
                }}
                muiToolbarAlertBannerProps={getAlert()}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                }}
                enableColumnOrdering={false}
                enableHiding={false}
                enableRowActions
                renderBottomToolbarCustomActions={() => {
                    return (
                        <div>
                            <Tooltip arrow title="Refresh">
                                <IconButton
                                    onClick={() => {
                                        setFetchRequired(true);
                                    }}
                                >
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                }}
                renderDetailPanel={({ row }) => (
                    <Box
                        sx={{
                            display: "grid",
                            margin: "auto",
                            gridTemplateColumns: "0.1fr 0.1fr",
                            width: "100%",
                        }}
                    >
                        <Button
                            onClick={() => {
                                setJsonOpen(true);
                                setJsonContent(row.original.json_orig);
                            }}
                            variant="outlined"
                            startIcon={<DataObjectIcon />}
                        >
                            Original Json
                        </Button>
                        <Button
                            onClick={() => {
                                setJsonOpen(true);
                                setJsonContent(row.original.json_cur);
                            }}
                            variant="outlined"
                            startIcon={<DataObjectIcon />}
                        >
                            Current Json
                        </Button>
                    </Box>
                )}
                renderRowActionMenuItems={({ row, table }) => [
                    <MenuItem
                        key="Notifications"
                        onClick={() => {
                            toNotificationsPage(row.original.userLabel);
                        }}
                    >
                        <ListItemIcon>
                            <NotificationsActiveIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: "h6" }}
                            primary="Notifications"
                        />
                    </MenuItem>,
                    <MenuItem
                        key="Alarms"
                        onClick={() => {
                            toAlarmsPage(row.original.userLabel);
                        }}
                    >
                        <ListItemIcon>
                            <AlarmIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: "h6" }}
                            primary="Alarms"
                        />
                    </MenuItem>,
                    <MenuItem
                        key="discovery"
                        onClick={() => {
                            setConfirmDiscoveryOpen(true);
                            setDiscoveryRow(row);
                        }}
                    >
                        <ListItemIcon>
                            <Refresh fontSize="large" color="success" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: "h6" }}
                            primary="Discovery"
                        />
                    </MenuItem>,
                    <MenuItem
                        key="Modify"
                        onClick={() => {
                            setModifyRow(row.original);
                            setModifyOpen(true);
                        }}
                    >
                        <ListItemIcon>
                            <ChangeCircleIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: "h6" }}
                            primary="Modify"
                        />
                    </MenuItem>,
                ]}
            />
            <ConfirmDialog
                title="Confirm Discovery"
                open={confirmDiscoveryOpen}
                setOpen={setConfirmDiscoveryOpen}
                onConfirm={handleDiscovery}
            >
                <Box sx={{ display: "block", gap: "1rem", p: "4px" }}>
                    <Typography variant="h5">
                        You are about to perform a Discovery on the following
                        Managed Element
                    </Typography>
                    <Typography variant="h6">
                        ID:{" "}
                        {discoveryRow &&
                            discoveryRow.original.ManagedElement_id}
                    </Typography>
                    <Typography variant="h6">
                        Name:{" "}
                        {discoveryRow && discoveryRow.original.me_userLabel}
                    </Typography>
                    <Typography variant="h6">
                        Original ID:{" "}
                        {discoveryRow && discoveryRow.original.me_origId}
                    </Typography>
                </Box>
            </ConfirmDialog>
            <Dialog
                maxWidth="xl"
                open={jsonOpen}
                PaperProps={{
                    sx: {
                        width: "100%",
                        height: "80%",
                        maxWidth: "50%",
                        minHeight: "50%",
                    },
                }}
            >
                <DialogContent>
                    <JsonViewer
                        value={jsonContent}
                        displayDataTypes={false}
                        theme={theme.palette.mode}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseJson} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {modifyOpen && (
                <EditDialog
                    rowData={modifyRow}
                    schema={editSchema}
                    sections={[
                        { sectionKey: "ME", sectionLabel: "Managed Element" },
                        { sectionKey: "NF", sectionLabel: "Network Function" },
                    ]}
                    open={modifyOpen}
                    onSave={handleSaveModify}
                    setOpen={setModifyOpen}
                />
            )}
            <Box m="20px">
                {/* ... */}
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                />
            </Box>
        </Box>
    );
};

export default NetworkFunctions;
