export const buildDataURL = (apiPath) => {
	return new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_API_BASE_PATH + '/snsm' + apiPath
			: process.env.REACT_APP_DEV_API_BASE_PATH + '/snsm' + apiPath,
		process.env.NODE_ENV === 'production'
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);
};

export const buildQueueURL = (apiPath) => {
	return new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_QUEUE_BASE_PATH + apiPath
			: process.env.REACT_APP_DEV_QUEUE_BASE_PATH + apiPath,
		process.env.NODE_ENV === 'production'
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);
};

export const buildRelayURL = () => {
	return new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_RELAY_BASE_PATH
			: process.env.REACT_APP_DEV_RELAY_BASE_PATH,
		process.env.NODE_ENV === 'production'
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);
};

export const buildChathubURL = () => {
	return new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_CHATHUB_BASE_PATH
			: process.env.REACT_APP_DEV_CHATHUB_BASE_PATH,
		process.env.NODE_ENV === 'production'
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);
};

export const convertToDate = (datetime) => {
	const formatted = new Date(datetime).toLocaleString('it-IT', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false,
	});
    const date = formatted.split(", ")[0];
    const time = formatted.split(", ")[1];
    return `${date} ${time}`;


};
