import {
	useTheme,
	Box,
	Button,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import Header from '../../components/Header/Header';
import useHttpService from '../../customHooks/useHttpService.js';
import { Refresh } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { useLocation } from 'react-router-dom';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { tokens } from '../../theme';
import { JsonViewer } from '@textea/json-viewer';
import { useSignalR } from '../../contexts/signalrContext';

const Notifications = () => {
	const { addMessageCallback, removeMessageCallback } = useSignalR();
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const location = useLocation();
	const tableInstanceRef = useRef(null);

	// main data table
	const [tableData, setTableData] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isErrorUpdate, setIsErrorUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState('');
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const [jsonOpen, setJsonOpen] = useState(false);
	const [jsonContent, setJsonContent] = useState(null);

	const fetchTableData = () => {
		const apiPath = '/nf_fcaps/assurance/NotifyMOI';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		//apiUrl.searchParams.set("size", `${pagination.pageSize}`);
		//apiUrl.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
		//apiUrl.searchParams.set("globalFilter", globalFilter ?? "");
		//apiUrl.searchParams.set("sorting", JSON.stringify(sorting ?? []));

		httpService.getAPI(
			apiUrl,
			tableData,
			setTableData,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	const getAlert = () => {
		if (isError) {
			return {
				color: 'error',
				children: 'Error loading data',
			};
		}

		return undefined;
	};

	const minifyID = (ID) => {
		return ID.split('-')[0] + ' ' + '\u00B7'.repeat(3);
	};

	const handleCloseJson = () => {
		setJsonOpen(false);
	};

	useEffect(() => {
		if (fetchRequired) {
			fetchTableData();
			setFetchRequired(false);
		}
	}, [fetchRequired]);

	useEffect(() => {
		if (location.state && location.state.objectInstance) {
			tableInstanceRef.current.setColumnFilters((prev) => [
				...prev,
				{ id: 'objectInstance', value: location.state.objectInstance },
			]);
		}
		if (location.state && location.state.notificationId) {
			tableInstanceRef.current.setColumnFilters((prev) => [
				...prev,
				{ id: 'id', value: location.state.notificationId },
			]);
		}
	}, [location]);

	useEffect(() => {
		const handleNewMessage = (message) => {
			// Process the new message here
			// Make decisions based on the message
			if (message.includes('NOTIFICATION__')) {
				setFetchRequired(true);
			}
		};

		// Register the message handling function
		addMessageCallback(handleNewMessage);

		// Clean up
		return () => {
			// Unregister the message handling function if necessary
			removeMessageCallback(handleNewMessage);
		};
	}, []);

	const columns = useMemo(
		() => [
			{
				accessorKey: 'id',
				header: 'ID',
				size: 80,
				enableColumnOrdering: false,
				enableEditing: false,
				enableSorting: false,
				enableClickToCopy: true,
				isInput: false,
				Cell: ({ row }) => (
					<Tooltip title={row.original.id} arrow>
						<Typography variant='h6' color='primary'>
							{minifyID(row.original.id)}
						</Typography>
					</Tooltip>
				),
			},
            {
				accessorKey: 'notificationId',
				header: 'Notification ID',
				size: 60,
				enableEditing: false,
				isInput: false,
			},
			{
				accessorKey: 'objectClass',
				header: 'Object Class',
				size: 60,
				isInput: false,
				enableEditing: false,
			},
			{
				accessorKey: 'objectInstance',
				header: 'Object Instance',
				size: 120,
				enableEditing: false,
				isInput: false,
			},
			{
				accessorKey: 'notificationType',
				header: 'Notification Type',
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: 'left',
				},
			},
			{
				accessorFn: (row) => new Date(row.inserted),
				header: 'Received',
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: 'left',
				},
				Cell: ({ cell }) => cell.getValue()?.toLocaleString(),
			},
			{
				accessorFn: (row) => new Date(row.eventTime),
				header: 'Event Time',
				maxSize: 220,
				enableEditing: false,
				isInput: false,
				muiTableBodyCellProps: {
					align: 'left',
				},
				Cell: ({ cell }) => cell.getValue()?.toLocaleString(),
			},
			// {
			// 	accessorKey: 'href',
			// 	header: 'href',
			// 	maxSize: 220,
			// 	enableEditing: false,
			// 	isInput: false,
			// 	muiTableBodyCellProps: {
			// 		align: 'left',
			// 	},
			// },
		],
		[]
	);

	return (
		<Box m='20px'>
			<Header
				title='Notifications'
				subtitle={'List of Notifications received from Management Nodes'}
			/>
			<MaterialReactTable
				tableInstanceRef={tableInstanceRef}
				enableStickyHeader
				muiTableContainerProps={{ sx: { maxHeight: '55vh' } }}
				displayColumnDefOptions={{
					'mrt-row-actions': {
						muiTableHeadCellProps: {
							align: 'left',
							sx: {
								fontWeight: 'bold',
								color: colors.grey[100],
								typography: 'h5',
							},
						},
						muiTableBodyCellProps: {
							align: 'left',
						},
						size: 40,
					},
				}}
				positionActionsColumn='last'
				columns={columns}
				data={tableData}
				getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: true,
					density: 'compact',
				}}
				enableDensityToggle={false}
				muiTableHeadCellProps={{
					align: 'left',
					sx: {
						fontWeight: 'bold',
						color: colors.grey[100],
						typography: 'h5',
					},
				}}
				muiTableBodyCellProps={{
					align: 'left',
					sx: {
						color: colors.grey[100],
						typography: 'h6',
					},
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				rowCount={rowCount}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isRefetching,
					sorting,
				}}
				enableColumnOrdering={false}
				enableHiding={false}
				enableRowActions={false}
				renderBottomToolbarCustomActions={() => {
					return (
						<div>
							<Tooltip arrow title='Refresh'>
								<IconButton
									onClick={() => {
										setFetchRequired(true);
									}}
								>
									<Refresh />
								</IconButton>
							</Tooltip>
						</div>
					);
				}}
				renderDetailPanel={({ row }) => (
					<Box
						sx={{
							display: 'grid',
							margin: 'auto',
							gridTemplateColumns: '1fr',
							width: '100%',
						}}
					>
						<Typography>href: {row.original.href}</Typography>
						<Typography>
							Attribute List:
							<Button
								onClick={() => {
									setJsonOpen(true);
									setJsonContent(row.original.attributeList);
								}}
								variant='outlined'
								startIcon={<DataObjectIcon />}
							></Button>
						</Typography>
					</Box>
				)}
			/>
			<Dialog
				maxWidth='xl'
				open={jsonOpen}
				PaperProps={{
					sx: {
						width: '100%',
						height: '80%',
						maxWidth: '50%',
						minHeight: '50%',
					},
				}}
			>
				<DialogContent>
					<JsonViewer
						value={jsonContent}
						displayDataTypes={false}
						theme={theme.palette.mode}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseJson} color='primary' autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default Notifications;
