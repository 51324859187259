import React from "react";
import {
    useTheme,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { tokens } from "../../theme";

const ConfirmDialog = (props) => {
    const { title, children, open, setOpen, onConfirm } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
            maxWidth="lg"
            PaperProps={{ sx:{ bgcolor: colors.primary[400] } }}
        >
            <DialogTitle variant="h4" id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    color="secondary"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
