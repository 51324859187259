import {
	useTheme,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	Select,
	Stack,
	TextField,
	Tooltip,
	InputLabel,
	FormControl,
	Typography,
} from '@mui/material';
import TableRowsIcon from '@mui/icons-material/TableRows';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import Header from '../../components/Header/Header';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import useHttpService from '../../customHooks/useHttpService.js';
import { ToastContainer, toast } from 'react-toastify';
import { Delete, Edit, ContentCopy, Refresh } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { tokens } from '../../theme';
import { Link, useNavigate } from 'react-router-dom';

const PlmnId = () => {
	const httpService = useHttpService();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const navigate = useNavigate();

	// main data table
	const [tableData, setTableData] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);
	const [isErrorUpdate, setIsErrorUpdate] = useState(false);
	const [isErrorCreate, setIsErrorCreate] = useState(false);
	const [isErrorDelete, setIsErrorDelete] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState('');
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [validationErrors, setValidationErrors] = useState({});

	const fetchTableData = () => {
		const apiPath = '/nf_fcaps/managed_object/PlmnId';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		//apiUrl.searchParams.set("size", `${pagination.pageSize}`);
		//apiUrl.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
		//apiUrl.searchParams.set("globalFilter", globalFilter ?? "");
		//apiUrl.searchParams.set("sorting", JSON.stringify(sorting ?? []));

		httpService.getAPI(
			apiUrl,
			tableData,
			setTableData,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	const handleCreateNewRow = (values) => {
		const apiPath = '/nf_fcaps/managed_object/PlmnId';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		delete values['id'];
		httpService.postAPI(apiUrl, values, setIsErrorCreate, setFetchRequired);
	};

	const handleSaveRowEdits = ({ exitEditingMode, row, values }) => {
		const apiPath = '/nf_fcaps/managed_object/PlmnId';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		if (!Object.keys(validationErrors).length) {
			httpService.putAPI(apiUrl, values, setIsErrorUpdate, setFetchRequired);
			exitEditingMode();
		}
	};

	const handleCancelRowEdits = () => {
		setValidationErrors({});
	};

	const handleDeleteRow = (row) => {
		if (
			!window.confirm(
				`Are you sure you want to delete ${row.getValue('userLabel')}`
			)
		) {
			return;
		}
		const apiPath = '/nf_fcaps/managed_object/PlmnId';
		const apiUrl = new URL(
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath
				: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath,
			process.env.NODE_ENV === 'production'
				? process.env.REACT_APP_PROD_API_BASE_URL
				: process.env.REACT_APP_DEV_API_BASE_URL
		);
		httpService.deleteAPI(
			apiUrl,
			{ ids: [row.id] },
			setIsErrorDelete,
			setFetchRequired
		);
	};

	const getAlert = () => {
		if (isError) {
			return {
				color: 'error',
				children: 'Error loading data',
			};
		} else if (isErrorCreate) {
			return {
				color: 'error',
				children: 'Error creating new item',
			};
		} else if (isErrorUpdate) {
			return {
				color: 'error',
				children: 'Error updating the item',
			};
		} else if (isErrorDelete) {
			return {
				color: 'error',
				children: 'Error deleting the item',
			};
		}

		return undefined;
	};

	const validateRequired = (value) => !!value.length;

	const getCommonEditTextFieldProps = useCallback(
		(cell) => {
			return {
				error: !!validationErrors[cell.id],
				helperText: validationErrors[cell.id],
				onBlur: (event) => {
					const isValid = validateRequired(event.target.value);
					if (!isValid) {
						//set validation error for cell if invalid
						setValidationErrors({
							...validationErrors,
							[cell.id]: `${cell.column.columnDef.header} is required`,
						});
					} else {
						//remove validation error for cell if valid
						delete validationErrors[cell.id];
						setValidationErrors({
							...validationErrors,
						});
					}
				},
			};
		},
		[validationErrors]
	);

	const minifyID = (ID) => {
		return ID.split('-')[0] + ' ' + '\u00B7'.repeat(3);
	};

	useEffect(() => {
		if (fetchRequired) {
			fetchTableData();
			setFetchRequired(false);
		}
	}, [fetchRequired]);

	useEffect(() => {
		if (isErrorDelete) {
			const timeId = setTimeout(() => {
				setIsErrorDelete(false);
			}, 5000);

			return () => {
				clearTimeout(timeId);
			};
		}
	}, [isErrorDelete]);

	useEffect(() => {
		if (isErrorUpdate) {
			const timeId = setTimeout(() => {
				setIsErrorUpdate(false);
			}, 5000);

			return () => {
				clearTimeout(timeId);
			};
		}
	}, [isErrorUpdate]);

	useEffect(() => {
		if (isErrorCreate) {
			const timeId = setTimeout(() => {
				setIsErrorCreate(false);
			}, 5000);

			return () => {
				clearTimeout(timeId);
			};
		}
	}, [isErrorCreate]);

	const columns = useMemo(
		() => [
			{
				accessorKey: 'id',
				header: 'ID',
				enableColumnOrdering: false,
				enableEditing: false,
				enableSorting: false,
				enableClickToCopy: true,
				size: 200,
				isInput: false,
			},
			{
				accessorKey: 'userLabel',
				header: 'Name',
				size: 100,
				isInput: true,
			},
			{
				accessorKey: 'mcc',
				header: 'MCC',
				size: 60,
				enableEditing: false,
				isInput: true,
			},
			{
				accessorKey: 'mnc',
				header: 'MNC',
				size: 60,
				enableEditing: false,
				isInput: true,
			},
		],
		[]
	);

	return (
		<Box m='20px'>
			<Header
				title='PLMNID'
				subtitle='List of Public Land Mobile Network Identifier (PLMNID)'
			/>
			<MaterialReactTable
				sx={{
					'&::-webkit-scrollbar': {
						width: '0.4em',
					},
					'&::-webkit-scrollbar-track': {
						boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#6486c2',
						outline: '1px solid slategrey',
						'&:hover': {
							backgroundColor: '#5A7BB0',
						},
						'&:active': {
							backgroundColor: '#4D6E9E',
						},
					},
				}}
				enableStickyHeader
				muiTableContainerProps={{ sx: { maxHeight: '55vh' } }}
				displayColumnDefOptions={{
					'mrt-row-actions': {
						muiTableHeadCellProps: {
							align: 'left',
							sx: {
								fontWeight: 'bold',
								color: colors.grey[100],
								typography: 'h5',
							},
						},
						muiTableBodyCellProps: {
							align: 'left',
						},
						size: 60,
					},
				}}
				positionActionsColumn='last'
				columns={columns}
				data={tableData}
				getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: true,
					showGlobalFilter: true,
					density: 'compact',
				}}
				enableDensityToggle={false}
				muiTableHeadCellFilterTextFieldProps={{
					sx: { m: '0.3rem 0', width: '100%' },
				}}
				muiTableHeadCellProps={{
					align: 'left',
					sx: {
						fontWeight: 'bold',
						color: colors.grey[100],
						typography: 'h5',
					},
				}}
				muiTableBodyCellProps={{
					align: 'left',
					sx: {
						color: colors.grey[100],
						typography: 'h6',
					},
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				rowCount={rowCount}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner:
						isError || isErrorCreate || isErrorUpdate || isErrorDelete,
					showProgressBars: isRefetching,
					sorting,
				}}
				editingMode='modal'
				enableColumnOrdering={false}
				enableHiding={false}
				onEditingRowSave={handleSaveRowEdits}
				onEditingRowCancel={handleCancelRowEdits}
				enableRowActions
				renderRowActionMenuItems={({ row, table }) => [
					<MenuItem key='edit' onClick={() => table.setEditingRow(row)}>
						<ListItemIcon>
							<Edit fontSize='large' />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: 'h6' }}
							primary='Edit'
						/>
					</MenuItem>,
					<MenuItem key='delete' onClick={() => handleDeleteRow(row)}>
						<ListItemIcon>
							<Delete fontSize='large' color='error' />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ variant: 'h6' }}
							primary='Delete'
						/>
					</MenuItem>,
				]}
				renderTopToolbarCustomActions={() => (
					<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
						<Button
							color='secondary'
							onClick={() => setCreateModalOpen(true)}
							variant='contained'
							startIcon={<AddCircleOutlineIcon sx={{ color: '#FFFFFF' }} />}>
							<Typography variant='h7' sx={{ color: '#FFFFFF' }}>
								Create PLMNID
							</Typography>
						</Button>
					</Box>
				)}
			/>
			<CreateNewItemModal
				columns={columns}
				open={createModalOpen}
				onClose={() => setCreateModalOpen(false)}
				onSubmit={handleCreateNewRow}
				createItemName={'PLMNID'}
			/>
		</Box>
	);
};

export const CreateNewItemModal = ({
	open,
	columns,
	onClose,
	onSubmit,
	createItemName,
}) => {
	const [values, setValues] = useState(() =>
		columns.reduce((acc, column) => {
			acc[column.accessorKey ?? ''] = '';
			return acc;
		}, {})
	);

	const handleSubmit = () => {
		onSubmit(values);
		onClose();
	};

	useEffect(() => {
		columns.forEach((column) => {
			if (column.fixedValue) {
				values[column.accessorKey] = column.fixedValue;
			}
		});
	}, []);

	return (
		<Dialog open={open}>
			<DialogTitle textAlign='center'>
				{'Create New ' + createItemName}
			</DialogTitle>
			<DialogContent>
				<form onSubmit={(e) => e.preventDefault()}>
					<Stack
						sx={{
							width: '100%',
							minWidth: { xs: '300px', sm: '360px', md: '400px' },
							gap: '1.5rem',
							mt: 2,
						}}>
						{columns.map(
							(column) =>
								column.isInput &&
								((column.fixedValue && (
									<TextField
										required
										key={column.accessorKey}
										label={column.header}
										name={column.accessorKey}
										disabled={true}
										value={column.fixedValue}
										onChange={(e) =>
											setValues({
												...values,
												[e.target.name]: e.target.value,
											})
										}
									/>
								)) ||
									(column.editVariant != 'select' && (
										<TextField
											required
											key={column.accessorKey}
											label={column.header}
											name={column.accessorKey}
											onChange={(e) =>
												setValues({
													...values,
													[e.target.name]: e.target.value,
												})
											}
										/>
									)) ||
									(column.editVariant == 'select' && (
										<FormControl fullWidth required>
											<InputLabel id='select-label'>{column.header}</InputLabel>
											<Select
												id={column.accessorKey}
												name={column.accessorKey}
												labelId='select-label'
												label={column.header}
												onChange={(e) =>
													setValues({
														...values,
														[e.target.name]: e.target.value,
													})
												}
												sx={{
													width: '100%',
												}}>
												<MenuItem value='' disabled>
													Select an option
												</MenuItem>
												{column.editSelectOptions.map((item) => (
													<MenuItem
														//key={item.value}
														value={item.value}
														//name={item.value}
													>
														{item.text}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)))
						)}
					</Stack>
				</form>
			</DialogContent>
			<DialogActions sx={{ p: '1.25rem' }}>
				<Button onClick={onClose}>Cancel</Button>
				<Button color='secondary' onClick={handleSubmit} variant='contained'>
					{'Create'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PlmnId;
