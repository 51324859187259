import React, { useCallback, useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	Select,
	Stack,
	TextField,
	Tooltip,
	InputLabel,
	FormControl,
	Typography,
} from '@mui/material';
import { Delete, Edit, ContentCopy, GetApp } from '@mui/icons-material';
import useHttpService from '../../customHooks/useHttpService.js';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';
import * as XLSX from 'xlsx';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const DataTable = ({
	apiPath,
	columns,
	createItemName,
	showCreateButton,
	showEditButton,
	showDeleteButton,
	showDownloadButton,
	showDownloadAllButton,
	showDiscoveryButton,
	DetailPanel,
	grouping,
}) => {
	const [tableData, setTableData] = useState([]);

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);
	const [rowCount, setRowCount] = useState(0);
	const [isErrorUpdate, setIsErrorUpdate] = useState(false);
	const [isErrorCreate, setIsErrorCreate] = useState(false);
	const [isErrorDelete, setIsErrorDelete] = useState(false);

	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState('');
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [validationErrors, setValidationErrors] = useState({});

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const httpService = useHttpService();

	const apiUrlGet = new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath.get
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath.get,
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_API_BASE_URL
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const apiUrlPost = new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath.post
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath.post,
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_API_BASE_URL
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const apiUrlPut = new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath.put
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath.put,
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_API_BASE_URL
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const apiUrlDelete = new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_API_BASE_PATH + apiPath.delete
			: process.env.REACT_APP_DEV_API_BASE_PATH + apiPath.delete,
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_API_BASE_URL
			: process.env.REACT_APP_DEV_API_BASE_URL
	);

	const columnVisibility = columns.reduce((acc, { accessorKey, isVisible }) => {
		acc[accessorKey] = isVisible;
		return acc;
	}, {});

	useEffect(() => {
		if (fetchRequired) {
			fetchTableData();
			setFetchRequired(false);
		}
	}, [
		apiUrlGet,
		tableData,
		setTableData,
		setRowCount,
		setIsLoading,
		setIsRefetching,
		setIsError,
		fetchRequired,
		setFetchRequired,
		columnFilters,
		globalFilter,
		pagination.pageIndex,
		pagination.pageSize,
		sorting,
	]);

	useEffect(() => {
		if (isErrorDelete) {
			const timeId = setTimeout(() => {
				setIsErrorDelete(false);
			}, 5000);

			return () => {
				clearTimeout(timeId);
			};
		}
	}, [isErrorDelete, setIsErrorDelete]);

	useEffect(() => {
		if (isErrorUpdate) {
			const timeId = setTimeout(() => {
				setIsErrorUpdate(false);
			}, 5000);

			return () => {
				clearTimeout(timeId);
			};
		}
	}, [isErrorUpdate, setIsErrorUpdate]);

	useEffect(() => {
		if (isErrorCreate) {
			const timeId = setTimeout(() => {
				setIsErrorCreate(false);
			}, 5000);

			return () => {
				clearTimeout(timeId);
			};
		}
	}, [isErrorCreate, setIsErrorCreate]);

	const getAlert = () => {
		if (isError) {
			return {
				color: 'error',
				children: 'Error loading data',
			};
		} else if (isErrorCreate) {
			return {
				color: 'error',
				children: 'Error creating new item',
			};
		} else if (isErrorUpdate) {
			return {
				color: 'error',
				children: 'Error updating the item',
			};
		} else if (isErrorDelete) {
			return {
				color: 'error',
				children: 'Error deleting the item',
			};
		}

		return undefined;
	};

	const fetchTableData = () => {
		//apiUrl.searchParams.set("size", `${pagination.pageSize}`);
		//apiUrl.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
		//apiUrl.searchParams.set("globalFilter", globalFilter ?? "");
		//apiUrl.searchParams.set("sorting", JSON.stringify(sorting ?? []));

		httpService.getAPI(
			apiUrlGet,
			tableData,
			setTableData,
			setRowCount,
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	const handleCreateNewRow = (values) => {
		delete values['id'];
		httpService.postAPI(apiUrlPost, values, setIsErrorCreate, setFetchRequired);
	};

	const handleSaveRowEdits = ({ exitEditingMode, row, values }) => {
		if (!Object.keys(validationErrors).length) {
			httpService.putAPI(apiUrlPut, values, setIsErrorUpdate, setFetchRequired);
			exitEditingMode();
		}
	};

	const handleCancelRowEdits = () => {
		setValidationErrors({});
	};

	const handleDeleteRow = (row) => {
		if (
			!window.confirm(
				`Are you sure you want to delete ${row.getValue('userLabel')}`
			)
		) {
			return;
		}
		httpService.deleteAPI(
			apiUrlDelete,
			{ ids: [row.id] },
			setIsErrorDelete,
			setFetchRequired
		);
	};

	const handleDownloadRow = (row) => {
		const apiData = row.original;

		const modifiedRow = Object.entries(apiData).reduce((acc, [key, value]) => {
			acc[key] = Array.isArray(value) ? JSON.stringify(value) : value;
			return acc;
		}, {});

		const ws = XLSX.utils.json_to_sheet([modifiedRow]);
		const csvOutput = XLSX.utils.sheet_to_csv(ws);

		const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(blob, `${row.id}.csv`);
		} else {
			const link = document.createElement('a');
			if (link.download !== undefined) {
				const url = URL.createObjectURL(blob);
				link.setAttribute('href', url);
				link.setAttribute('download', `${row.id}.csv`);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	};

	const handleDownloadAll = () => {
		const csvRows = [Object.keys(tableData[0])];

		tableData.forEach((row) => {
			const modifiedRow = { ...row };
			for (const [key, value] of Object.entries(row)) {
				if (Array.isArray(value)) {
					modifiedRow[key] = JSON.stringify(value);
				}
			}
			csvRows.push(Object.values(modifiedRow));
		});

		const csvContent = csvRows
			.map((row) => row.map(escapeCsvValue).join(','))
			.join('\n');

		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(blob, 'AllRowsData.csv');
		} else {
			const link = document.createElement('a');
			if (link.download !== undefined) {
				const url = URL.createObjectURL(blob);
				link.setAttribute('href', url);
				link.setAttribute('download', 'AllRowsData.csv');
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	};

	const escapeCsvValue = (value) => {
		if (value.includes(',') || value.includes('"')) {
			return `"${value.replace(/"/g, '""')}"`;
		}
		return value;
	};

	const getCommonEditTextFieldProps = useCallback(
		(cell) => {
			return {
				error: !!validationErrors[cell.id],
				helperText: validationErrors[cell.id],
				onBlur: (event) => {
					const isValid =
						cell.column.id === 'email'
							? validateEmail(event.target.value)
							: cell.column.id === 'age'
							? validateAge(+event.target.value)
							: validateRequired(event.target.value);
					if (!isValid) {
						setValidationErrors({
							...validationErrors,
							[cell.id]: `${cell.column.columnDef.header} is required`,
						});
					} else {
						delete validationErrors[cell.id];
						setValidationErrors({
							...validationErrors,
						});
					}
				},
			};
		},
		[validationErrors]
	);

	const downloadRowDataAsExcelFile = (row) => {
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet([row]);
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
		XLSX.writeFile(workbook, 'RowData.xlsx');
	};

	return (
		<Box>
			<MaterialReactTable
				enableStickyHeader
				muiTableContainerProps={{
					sx: {
						maxHeight: '55vh',
						'&::-webkit-scrollbar': {
							width: '0.4em',
						},
						'&::-webkit-scrollbar-track': {
							boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#6486c2',
							outline: '1px solid slategrey',
							'&:hover': {
								backgroundColor: '#5A7BB0',
							},
							'&:active': {
								backgroundColor: '#4D6E9E',
							},
						},
					},
				}}
				displayColumnDefOptions={{
					'mrt-row-actions': {
						muiTableHeadCellProps: {
							align: 'left',
						},
						size: 120,
					},
				}}
				positionActionsColumn='last'
				muiTableBodyCellCopyButtonProps={{
					startIcon: <ContentCopy />,
				}}
				columns={columns}
				data={tableData}
				getRowId={(row) => row.id}
				initialState={{
					showColumnFilters: false,
					columnVisibility: columnVisibility,
				}}
				muiToolbarAlertBannerProps={getAlert()}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				rowCount={rowCount}
				renderDetailPanel={DetailPanel}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner:
						isError || isErrorCreate || isErrorUpdate || isErrorDelete,
					showProgressBars: isRefetching,
					sorting,
				}}
				editingMode='modal'
				enableColumnOrdering={false}
				enableEditing
				enableHiding={false}
				onEditingRowSave={handleSaveRowEdits}
				onEditingRowCancel={handleCancelRowEdits}
				renderRowActions={({ row, table }) => (
					<Box sx={{ display: 'flex', gap: '1rem' }}>
						{showEditButton && (
							<Tooltip arrow placement='left' title='Edit'>
								<IconButton onClick={() => table.setEditingRow(row)}>
									<Edit />
								</IconButton>
							</Tooltip>
						)}
						{showDeleteButton && (
							<Tooltip arrow placement='right' title='Delete'>
								<IconButton color='error' onClick={() => handleDeleteRow(row)}>
									<Delete />
								</IconButton>
							</Tooltip>
						)}
						{showDownloadButton && (
							<Tooltip arrow placement='right' title='Download .csv'>
								<IconButton
									color='#000000'
									onClick={() => handleDownloadRow(row)}>
									<GetApp />
								</IconButton>
							</Tooltip>
						)}
					</Box>
				)}
				renderTopToolbarCustomActions={() => (
					<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
						{showCreateButton && (
							<Button
								color='secondary'
								onClick={() => setCreateModalOpen(true)}
								startIcon={<AddCircleOutlineIcon sx={{ color: '#FFFFFF' }} />}
								variant='contained'>
								{'Create New ' + createItemName}
							</Button>
						)}
						{showDownloadAllButton && (
							<Button
								color='secondary'
								variant='contained'
								onClick={handleDownloadAll}>
								Download All Data (.csv)
							</Button>
						)}
					</Box>
				)}
			/>
			{showCreateButton && (
				<CreateNewItemModal
					columns={columns}
					open={createModalOpen}
					onClose={() => setCreateModalOpen(false)}
					onSubmit={handleCreateNewRow}
					createItemName={createItemName}
				/>
			)}
		</Box>
	);
};

export const CreateNewItemModal = ({
	open,
	columns,
	onClose,
	onSubmit,
	createItemName,
}) => {
	const [values, setValues] = useState(() =>
		columns.reduce((acc, column) => {
			acc[column.accessorKey ?? ''] = '';
			return acc;
		}, {})
	);

	const handleSubmit = () => {
		onSubmit(values);
		onClose();
	};

	useEffect(() => {
		columns.forEach((column) => {
			if (column.fixedValue) {
				values[column.accessorKey] = column.fixedValue;
			}
		});
	}, []);

	return (
		<Dialog open={open}>
			<DialogTitle textAlign='center'>
				{'Create New ' + createItemName}
			</DialogTitle>
			<DialogContent>
				<form onSubmit={(e) => e.preventDefault()}>
					<Stack
						sx={{
							width: '100%',
							minWidth: { xs: '300px', sm: '360px', md: '400px' },
							gap: '1.5rem',
							mt: 2,
						}}>
						{columns.map(
							(column) =>
								column.isInput &&
								((column.fixedValue && (
									<TextField
										key={column.accessorKey}
										label={column.header}
										name={column.accessorKey}
										disabled={true}
										value={column.fixedValue}
										onChange={(e) =>
											setValues({
												...values,
												[e.target.name]: e.target.value,
											})
										}
									/>
								)) ||
									(column.editVariant != 'select' && (
										<TextField
											key={column.accessorKey}
											label={column.header}
											name={column.accessorKey}
											onChange={(e) =>
												setValues({
													...values,
													[e.target.name]: e.target.value,
												})
											}
										/>
									)) ||
									(column.editVariant == 'select' && (
										<FormControl fullWidth>
											<InputLabel id='select-label'>{column.header}</InputLabel>
											<Select
												id={column.accessorKey}
												name={column.accessorKey}
												labelId='select-label'
												label={column.header}
												onChange={(e) =>
													setValues({
														...values,
														[e.target.name]: e.target.value,
													})
												}
												sx={{
													width: '100%',
												}}>
												<MenuItem value='' disabled>
													Select an option
												</MenuItem>
												{column.editSelectOptions.map((item) => (
													<MenuItem
														//key={item.value}
														value={item.value}
														//name={item.value}
													>
														{item.text}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)))
						)}
					</Stack>
				</form>
			</DialogContent>
			<DialogActions sx={{ p: '1.25rem' }}>
				<Button onClick={onClose}>Cancel</Button>
				<Button color='secondary' onClick={handleSubmit} variant='contained'>
					{'Create'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
	!!email.length &&
	email
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
const validateAge = (age) => age >= 18 && age <= 50;

export default DataTable;
